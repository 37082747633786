import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Theme } from "@mui/material";
import { ThunkDispatch } from "@reduxjs/toolkit";
import * as React from "react";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import {
  AuthAction,
  login,
} from "../../../middleware/api/auth/stateEntity/actions";
import { RootState } from "../../../store/store";
import { SPACING } from "../../../theme/spacing";
import Checkbox from "../../atoms/@form/Checkbox";
import FormControlLabel from "../../atoms/@form/FormControlLabel";
import TextField from "../../atoms/@form/TextField";
import useForm from "../../atoms/@form/useForm";
import Button from "../../atoms/Button";
import Captcha from "../../atoms/Captcha";
import Link from "../../atoms/Link";
import Typography from "../../atoms/Typography";
import TextFieldPassword from "../../molecules/TextFieldPassword";
import Authorization from "../../templates/Authorization";
import { PathPage } from "../pathPage";
import { validationSchema } from "./form";

const PageSignIn: FC<Props> = (props: Props) => {
  const form = useForm({
    defaultValues: {
      email: "",
      password: "",
      isRememberMe: false,
    },
    resolver: yupResolver(validationSchema),
  });

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = form;

  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AuthAction>>();
  const [isLoading, setIsLoading] = React.useState(false);

  const onSubmit = (data: any) => {
    dispatch(
      login({ ...data }, (state) => {
        setIsLoading(state);
      }),
    );
  };

  const { classes } = useStyles();
  return (
    <Authorization>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Box className={classes.sectionTitle}>
          <Typography variant="h1">Sign In</Typography>
          <Typography
            variant="body1"
            style={{ marginTop: 10, display: "flex" }}>
            Don&apos;t have an account?
            <Link variant="body1" component={RouterLink} to={PathPage.SignUp}>
              &nbsp;Sign Up {">"}
            </Link>
          </Typography>
        </Box>
        <Box className={classes.sectionContent}>
          <TextField
            label="Work Email"
            type="email"
            {...register("email")}
            error={!!errors.email}
            helperText={errors.email?.message}
          />

          <TextFieldPassword
            label={"Password"}
            {...register("password")}
            error={!!errors.password}
            helperText={errors.password?.message}
          />
        </Box>
        <Box
          style={{
            marginTop: SPACING.s_60,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
          <FormControlLabel
            {...register("isRememberMe")}
            control={<Checkbox />}
            label="Remember Me"
            componentsProps={{ typography: { variant: "overline" } }}
          />
          <Link
            variant="h4"
            component={RouterLink}
            to={PathPage.ForgotPassword}>
            Forgot Your Password?
          </Link>
        </Box>

        <Box style={{ marginTop: SPACING.s_32 }}>
          <Captcha form={form} />
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          style={{ marginTop: SPACING.s_60 }}>
          <Button typeClassName="primary" type="submit" isSpinner={isLoading}>
            Sign In
          </Button>
        </Box>
      </Box>
    </Authorization>
  );
};

export default PageSignIn;

interface Props {}

type ClassNames = "root" | "section" | "sectionTitle" | "sectionContent";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {},
  section: {},
  sectionTitle: {
    marginBottom: SPACING.s_40,
  },
  sectionContent: {
    display: "flex",
    flexDirection: "column",

    "& > *": {
      marginTop: `${SPACING.s_40}px !important`,
    },

    "& > :first-of-type": {
      marginTop: `0 !important`,
    },
  },
}));
