export enum PathPage {
  Home = "/",
  SignIn = "/sign-in",
  ResetPassword = "/reset-password",
  ForgotPassword = "/forgot-password",
  SignUp = "/sign-up",
  SignUpSurvey = "/sign-up-survey",
  ConfirmEmail = "/confirm-email",
  Overview = "/overview",

  MyData = "/my-data",
  CartMiddleware = "/user/cartMiddleware/:packageId/:period",
  PaymentSuccess = "/subscriptions/success/:id",
  PaymentCancel = "/subscriptions/cancel/:id",
  Subscriptions = "/subscriptions",
  Resource = "/resource/:id",
  SavedSearch = "/my-data/saved-search/:name",
  Subscription = "/subscription",
  ManageUsers = "/manage-users",
  MyRequests = "/my-requests",
  Resources = "/resources",
  Settings = "/settings",
  Support = "/support",
  Admins = "/admins",
  MasterData = "/master-data",
  Users = "/users",
  Requests = "/requests",
  FlaggedFirms = "/flagged-firms",
  Industries = "/industries",
  Profile = "/profile",
  AccountDetails = "/account-details",
}
