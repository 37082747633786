enum Primary {
  darkGreen = "#122E31",
  dark = "#4A4A4A",
  orange = "#F5A840",
  green = "#428884",
  lightGreen = "#62B091",
  grey50 = "#858585",
  grey40 = "#C7C7C7",
  grey30 = "#E2E2E2",
  grey20 = "#EFEFEF",
  grey10 = "#F9F9F9",
  creme = "#F8F6F1",
  white = "#FFFFFF",
}

enum Secondary {
  dark = "#4a4a4acc",
  green = "#254C50",
  lightGreen20 = "#CEEDC6",
  lightGreen10 = "#EBF5F3",
  darkRed = "#A5493E",
  red = "#CA5628",
  lightRed20 = "#E36B3C",
  darkOrange = "#D68C28",
  lightRed = "#FFEEEC",
  lightOrange = "#FFF7EC",
  purple = "#9CB7FF",
  blue = "#69D7FA",
  yellow = "#FFCF52",
}

export const COLORS = {
  primary: Primary,
  secondary: Secondary,
};
