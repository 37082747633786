export const updateCacheItem = <T extends Record<string, any>>(
  apiPath: string,
  data: T,
  ttl: number = 3600000, // Default 1 hour in milliseconds
): void => {
  const item = {
    data,
    expiry: Date.now() + ttl,
  };
  sessionStorage.setItem(apiPath, JSON.stringify(item));
};

export const removeCacheItem = (apiPath: string): void => {
  sessionStorage.removeItem(apiPath);
};
