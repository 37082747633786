import { Box } from "@mui/material";
import * as React from "react";
import { ComponentProps, FC } from "react";

import { COLORS } from "../../../../../../theme/colors";
import Radio from "../../../../../atoms/@form/Radio";
import Typography from "../../../../../atoms/Typography";
import { ResearchRequestTypeEnum } from "../../index";
import { useStyles } from "../../styles";
import RadioGroupItem from "../RadioGroupItem";

const StepOne: FC<Props> = ({
  selectedDialogType,
  handleChangeDialogType,
}: Props) => {
  const { classes } = useStyles();

  const handleSelectSpecific = () => {
    handleChangeDialogType({
      target: { value: ResearchRequestTypeEnum.specific },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const handleSelectGeneral = () => {
    handleChangeDialogType({
      target: { value: ResearchRequestTypeEnum.general },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <>
      <Typography
        variant={"body1"}
        style={{
          color: COLORS.primary.darkGreen,
          marginTop: 48,
        }}>
        Please choose from the following:
      </Typography>

      <RadioGroupItem onClick={handleSelectSpecific}>
        <Radio
          checked={selectedDialogType === ResearchRequestTypeEnum.specific}
          onChange={handleChangeDialogType}
          value={ResearchRequestTypeEnum.specific}
          name="radio-buttons"
          style={{ marginRight: 14 }}
        />
        <Caption
          title={"Specific Office or Contact"}
          description={
            "Share information about a particular office or contact whose details are missing from our database."
          }
        />
      </RadioGroupItem>
      <RadioGroupItem onClick={handleSelectGeneral}>
        <Radio
          checked={selectedDialogType === ResearchRequestTypeEnum.general}
          onChange={handleChangeDialogType}
          value={ResearchRequestTypeEnum.general}
          name="radio-buttons"
          style={{ marginRight: 14 }}
        />
        <Caption
          title={"General Family Office Profile"}
          description={
            "Share details about the ideal Family Office profile you are seeking."
          }
        />
      </RadioGroupItem>
    </>
  );
};

const Caption = ({ title, description }: any) => {
  return (
    <Box style={{ display: "flex", flexDirection: "column" }}>
      <Typography variant={"h3"}>{title}</Typography>
      <Typography variant={"body1"} style={{ marginTop: 4 }}>
        {description}
      </Typography>
    </Box>
  );
};

interface Props extends ComponentProps<any> {
  selectedDialogType: any;
  handleChangeDialogType: any;
}

export default StepOne;
