import { Box } from "@mui/material";
import * as React from "react";
import { ComponentProps, FC, useContext } from "react";

import TextField from "../../../../../atoms/@form/TextField";
import { DialogResearchRequestContext } from "../../context";
import { useStyles } from "../../styles";

const SpecificFirmOrContact: FC<Props> = (props: Props) => {
  const { classes } = useStyles();

  const {
    mainForm: {
      formState: { errors, isValid },
      register,
      setValue,
      getValues,
    },
  } = useContext(DialogResearchRequestContext);

  return (
    <>
      <Box className={classes.form} style={{ marginTop: 48 }}>
        <TextField
          label="Office Name"
          fullWidth
          {...register("specific.firmName")}
          error={!!errors.specific?.firmName}
          helperText={errors.specific?.firmName?.message}
        />
        <TextField
          label="Website"
          fullWidth
          {...register("specific.website")}
          error={!!errors.specific?.website}
          helperText={errors.specific?.website?.message}
        />
        <Box display="flex">
          <TextField
            label="Country"
            fullWidth
            style={{ marginRight: 48 }}
            {...register("specific.country")}
            error={!!errors.specific?.country}
            helperText={errors.specific?.country?.message}
          />
          <TextField
            label="City"
            fullWidth
            {...register("specific.city")}
            error={!!errors.specific?.city}
            helperText={errors.specific?.city?.message}
          />
        </Box>
        <TextField
          label="Contact"
          fullWidth
          {...register("specific.firmContactPerson")}
          error={!!errors.specific?.firmContactPerson}
          helperText={errors.specific?.firmContactPerson?.message}
        />
        <TextField
          fullWidth
          label="Notes"
          tooltip="Please share any additional details to assist our research team in adding the requested office and/or contact."
          maxRows={4}
          multiline
          {...register("specific.comment")}
          error={!!errors.specific?.comment}
          helperText={errors.specific?.comment?.message}
        />
      </Box>
    </>
  );
};

interface Props extends ComponentProps<any> {}

export default SpecificFirmOrContact;
