import { ReactComponent as IconArrowCounterClockwise } from "../../../assets/icons/arrow-counter-clockwise.svg";
import { ReactComponent as IconArrowSquareOut } from "../../../assets/icons/arrow-square-out.svg";
import { ReactComponent as IconArrowTurnUpLeft } from "../../../assets/icons/arrow-turn-up-left.svg";
import { ReactComponent as IconBell } from "../../../assets/icons/bell.svg";
import { ReactComponent as IconBellSlash } from "../../../assets/icons/bell-slash.svg";
import { ReactComponent as IconBookBookmark } from "../../../assets/icons/book-bookmark.svg";
import { ReactComponent as IconBooks } from "../../../assets/icons/books.svg";
import { ReactComponent as IconCalendar } from "../../../assets/icons/calendar.svg";
import { ReactComponent as IconCheck } from "../../../assets/icons/check.svg";
import { ReactComponent as IconChevronDown } from "../../../assets/icons/chevron-down.svg";
import { ReactComponent as IconChevronLeft } from "../../../assets/icons/chevron-left.svg";
import { ReactComponent as IconChevronRight } from "../../../assets/icons/chevron-right.svg";
import { ReactComponent as IconChevronUp } from "../../../assets/icons/chevron-up.svg";
import { ReactComponent as IconCircleCheck } from "../../../assets/icons/circle-check.svg";
import { ReactComponent as IconCircleInfo } from "../../../assets/icons/circle-info.svg";
import { ReactComponent as IconCirclePlus } from "../../../assets/icons/circle-plus.svg";
import { ReactComponent as IconCircleUserPlus } from "../../../assets/icons/circle-user-plus.svg";
import { ReactComponent as IconClose } from "../../../assets/icons/close.svg";
import { ReactComponent as IconDeviceMobileCamera } from "../../../assets/icons/device-mobile-camera.svg";
import { ReactComponent as IconDownload } from "../../../assets/icons/download.svg";
import { ReactComponent as IconEnvelope } from "../../../assets/icons/envelope.svg";
import { ReactComponent as IconEye } from "../../../assets/icons/eye.svg";
import { ReactComponent as IconEyeSlash } from "../../../assets/icons/eye-slash.svg";
import { ReactComponent as IconFacebook } from "../../../assets/icons/facebook.svg";
import { ReactComponent as IconFileAdd } from "../../../assets/icons/file-add.svg";
import { ReactComponent as IconFileDoc } from "../../../assets/icons/file-doc.svg";
import { ReactComponent as IconFileList } from "../../../assets/icons/file-list.svg";
import { ReactComponent as IconFileMagnifyingGlass } from "../../../assets/icons/file-magnifying-glass.svg";
import { ReactComponent as IconFilePdf } from "../../../assets/icons/file-pdf.svg";
import { ReactComponent as IconFilePpt } from "../../../assets/icons/file-ppt.svg";
import { ReactComponent as IconFileVideo } from "../../../assets/icons/file-video.svg";
import { ReactComponent as IconFileXls } from "../../../assets/icons/file-xls.svg";
import { ReactComponent as IconFileZip } from "../../../assets/icons/file-zip.svg";
import { ReactComponent as IconFilter } from "../../../assets/icons/filter.svg";
import { ReactComponent as IconFlag } from "../../../assets/icons/flag.svg";
import { ReactComponent as IconGaugeMax } from "../../../assets/icons/gauge-max.svg";
import { ReactComponent as IconGear } from "../../../assets/icons/gear.svg";
import { ReactComponent as IconImage } from "../../../assets/icons/image.svg";
import { ReactComponent as IconLifeRing } from "../../../assets/icons/life-ring.svg";
import { ReactComponent as IconLinkedin } from "../../../assets/icons/linkedin.svg";
import { ReactComponent as IconList } from "../../../assets/icons/list.svg";
import { ReactComponent as IconListCheck } from "../../../assets/icons/list-check.svg";
import { ReactComponent as IconLock } from "../../../assets/icons/lock.svg";
import { ReactComponent as IconMagnifyingGlass } from "../../../assets/icons/magnifying-glass.svg";
import { ReactComponent as IconMegaphone } from "../../../assets/icons/megaphone.svg";
import { ReactComponent as IconMessageDots } from "../../../assets/icons/message-dots.svg";
import { ReactComponent as IconPackages } from "../../../assets/icons/packages.svg";
import { ReactComponent as IconPaperPlane } from "../../../assets/icons/paper-plane.svg";
import { ReactComponent as IconPencil } from "../../../assets/icons/pencil.svg";
import { ReactComponent as IconRotate } from "../../../assets/icons/rotate.svg";
import { ReactComponent as IconSort } from "../../../assets/icons/sort.svg";
import { ReactComponent as IconSortActiveArrowAsc } from "../../../assets/icons/sort-active-arrow-asc.svg";
import { ReactComponent as IconSortActiveArrowDesc } from "../../../assets/icons/sort-active-arrow-desc.svg";
import { ReactComponent as IconSortDown } from "../../../assets/icons/sort-down.svg";
import { ReactComponent as IconStar } from "../../../assets/icons/star.svg";
import { ReactComponent as IconSuitcase } from "../../../assets/icons/suitcase.svg";
import { ReactComponent as IconTable } from "../../../assets/icons/table.svg";
import { ReactComponent as IconTrash } from "../../../assets/icons/trash.svg";
import { ReactComponent as IconTrashCan } from "../../../assets/icons/trash-can.svg";
import { ReactComponent as IconTwitter } from "../../../assets/icons/twitter.svg";
import { ReactComponent as IconUser } from "../../../assets/icons/user.svg";
import { ReactComponent as IconUserBrowser } from "../../../assets/icons/user-browser.svg";
import { ReactComponent as IconUsers } from "../../../assets/icons/users.svg";
import { ReactComponent as IconWarning } from "../../../assets/icons/warning.svg";
import { ReactComponent as IconWarningDiamond } from "../../../assets/icons/warning-diamond.svg";

export type DomainIconName = keyof typeof DOMAIN_ICONS;

export const DOMAIN_ICONS = {
  sortDown: IconSortDown,
  bell: IconBell,
  bellSlash: IconBellSlash,
  trashCan: IconTrashCan,
  gear: IconGear,
  download: IconDownload,
  user: IconUser,
  rotate: IconRotate,
  listCheck: IconListCheck,
  magnifyingGlass: IconMagnifyingGlass,
  chevronUp: IconChevronUp,
  fileList: IconFileList,
  pencil: IconPencil,
  fileMagnifyingGlass: IconFileMagnifyingGlass,
  arrowTurnUpLeft: IconArrowTurnUpLeft,
  check: IconCheck,
  circlePlus: IconCirclePlus,
  filter: IconFilter,
  flag: IconFlag,
  list: IconList,
  chevronLeft: IconChevronLeft,
  lifeRing: IconLifeRing,
  chevronDown: IconChevronDown,
  circleInfo: IconCircleInfo,
  eye: IconEye,
  books: IconBooks,
  gaugeMax: IconGaugeMax,
  sort: IconSort,
  close: IconClose,
  suitcase: IconSuitcase,
  circleCheck: IconCircleCheck,
  users: IconUsers,
  eyeSlash: IconEyeSlash,
  chevronRight: IconChevronRight,
  userBrowser: IconUserBrowser,
  packages: IconPackages,
  star: IconStar,
  trash: IconTrash,
  megaphone: IconMegaphone,
  circleUserPlus: IconCircleUserPlus,
  calendar: IconCalendar,
  fileAdd: IconFileAdd,
  messageDots: IconMessageDots,
  warning: IconWarning,
  warningDiamond: IconWarningDiamond,
  bookBookmark: IconBookBookmark,
  sortActiveArrowDesc: IconSortActiveArrowDesc,
  sortActiveArrowAsc: IconSortActiveArrowAsc,
  table: IconTable,
  fileVideo: IconFileVideo,
  filePdf: IconFilePdf,
  fileXls: IconFileXls,
  fileDoc: IconFileDoc,
  fileZip: IconFileZip,
  filePpt: IconFilePpt,
  lock: IconLock,
  image: IconImage,
  arrowCounterClockwise: IconArrowCounterClockwise,
  arrowSquareOut: IconArrowSquareOut,
  deviceMobileCamera: IconDeviceMobileCamera,
  envelope: IconEnvelope,
  facebook: IconFacebook,
  twitter: IconTwitter,
  linkedin: IconLinkedin,
  paperPlane: IconPaperPlane,
};
