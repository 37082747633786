// storage.ts
import { del, get, set } from "idb-keyval";

// Simple IndexedDB storage adapter
const createIDBStorage = () => ({
  getItem: async (key: string) => {
    try {
      const value = await get(key);
      return value;
    } catch {
      return null;
    }
  },
  setItem: async (key: string, value: any) => {
    try {
      // Store the raw value without JSON.stringify
      await set(key, value);
    } catch (err) {
      console.error("IndexedDB setItem error:", err);
    }
  },
  removeItem: async (key: string) => {
    try {
      await del(key);
    } catch (err) {
      console.error("IndexedDB removeItem error:", err);
    }
  },
});

export const idbStorage = createIDBStorage();
