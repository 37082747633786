import { Link as MUILink, LinkProps, Theme } from "@mui/material";
import clsx from "clsx";
import React, { FC, forwardRef } from "react";
import { makeStyles } from "tss-react/mui";

import { COLORS } from "../../../theme/colors";

const Link: FC<Props> = forwardRef(
  ({ children, onClick, disabled, href, ...rest }, ref) => {
    const { classes } = useStyles();

    const handleClick = (
      event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    ) => {
      if (disabled) {
        event.preventDefault();
        return;
      }
      if (onClick) {
        onClick(event);
      }
    };

    const linkProps: Partial<LinkProps> = {
      className: clsx(classes.root, { [classes.disabled]: disabled }),
      onClick: handleClick,
      ...rest,
    };

    if (href) {
      linkProps.href = href;
      linkProps.target = "_blank";
      linkProps.rel = "noopener noreferrer";
    }

    return (
      <MUILink {...linkProps} {...rest}>
        {children}
      </MUILink>
    );
  },
);

Link.displayName = "Link";

export default Link;

interface Props extends LinkProps<any> {}

type ClassNames = "root" | "disabled";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    wordBreak: "break-all",
    width: "fit-content",
    textDecoration: "unset",
    "&.MuiTypography-h4": {},
    "&:hover": {
      cursor: "pointer !important",
    },
  },
  disabled: {
    pointerEvents: "none",
    cursor: "default !important",
    color: COLORS.primary.grey40,
    "&:hover": {
      cursor: "default !important",
      textDecoration: "none",
    },
  },
}));
