import { BadgeProps, Theme } from "@mui/material";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { makeStyles } from "tss-react/mui";

const PortalHeaderTablet: FC<Props> = ({ children, ...rest }) => {
  const [targetNodeOnPageLoad, setTargetNodeOnPageLoad] = useState<any>(null);
  const targetNodeOnRoute = document.getElementById("portal-header-tablet");
  useEffect(() => {
    const callback = (mutationsList: any, observer: any) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList") {
          const found = document.getElementById("portal-header-tablet");
          if (found) {
            setTargetNodeOnPageLoad(found);
            observer.disconnect();
            break;
          }
        }
      }
    };

    const observer = new MutationObserver(callback);

    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);

  if (targetNodeOnRoute) {
    return createPortal(children, targetNodeOnRoute);
  }
  if (targetNodeOnPageLoad) {
    return createPortal(children, targetNodeOnPageLoad);
  }

  return <></>;
};

export default PortalHeaderTablet;

interface Props extends BadgeProps {}

type ClassNames = "root";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {},
}));
