import {
  Theme,
  Typography as MUITypography,
  TypographyProps,
} from "@mui/material";
import clsx from "clsx";
import React, { FC, forwardRef } from "react";
import { makeStyles } from "tss-react/mui";

import LinesEllipsis from "./components/reactLinesEllipsis";

const Typography: FC<Props> = forwardRef(
  ({ className, children, isEllipsis, ellipsis, ...rest }, ref) => {
    const { classes } = useStyles();

    if (ellipsis) {
      return (
        <MUITypography
          className={clsx(classes.root, className, {
            [classes.ellipsis]: isEllipsis,
          })}
          ref={ref}
          {...rest}>
          <LinesEllipsis {...ellipsis} />
        </MUITypography>
      );
    }
    return (
      <MUITypography
        className={clsx(classes.root, className, {
          [classes.ellipsis]: isEllipsis,
        })}
        ref={ref}
        {...rest}>
        {children}
      </MUITypography>
    );
  },
);

Typography.displayName = "Typography";

export default Typography;

interface Props extends TypographyProps {
  isEllipsis?: boolean;
  ellipsis?: any;
}

type ClassNames = "root" | "ellipsis";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    whiteSpace: "pre-line",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    wordBreak: "break-word",
    "& .MuiTypography-paragraph": {
      marginBottom: 24,
    },
  },
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "3",
    WebkitBoxOrient: "vertical",
  },
}));
