export enum SPACING {
  s_60 = 60,
  s_48 = 48,
  s_40 = 40,
  s_32 = 32,
  s_34 = 34,
  s_30 = 30,
  s_28 = 28,
  s_24 = 24,
  s_20 = 20,
  s_18 = 18,
  s_16 = 16,
  s_12 = 12,
  s_10 = 10,
  s_8 = 8,
  s_4 = 4,
  s_2 = 2,
}
