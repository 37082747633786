import { BadgeProps, Popover, Theme } from "@mui/material";
import moment from "moment";
import React, { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { DefaultEventsMap } from "socket.io/dist/typed-events";
import io, { Socket } from "socket.io-client";
import { makeStyles } from "tss-react/mui";

import { StateAuth } from "../../../../../../../middleware/api/auth/stateEntity/reducer";
import { BASE_URL } from "../../../../../../../middleware/api/endpoints.api";
import { NotificationsEntity } from "../../../../../../../middleware/api/entities/NotificationsEntity";
import { AppState } from "../../../../../../../store/rootReducer";
import { COLORS } from "../../../../../../../theme/colors";
import { updateObjectsByIds } from "../../../../../../../utils/array.util";
import Badge from "../../../../../../atoms/Badge";
import IconButton from "../../../../../../atoms/IconButton";
import SvgIcon from "../../../../../../atoms/SvgIcon";
import Notifications from "../../../../../../organisms/Notifications";
import { LIMIT, useHookList } from "./useHookList";

const NotificationsWithIcon: FC<Props> = ({ children, ...rest }) => {
  const { setNotifications, notifications, requestState, changePage } =
    useHookList();
  const { user } = useSelector<AppState, StateAuth>((state) => state.auth);

  const [countToRead, setCountToRead] = useState(0);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (requestState?.allData?.countToRead) {
      setCountToRead(requestState.allData.countToRead);
    }
  }, [requestState?.allData?.countToRead]);

  const socketRef = useRef<Socket<DefaultEventsMap, DefaultEventsMap> | null>(
    null,
  );
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    if (user && user.id) {
      if (!socketRef.current) {
        socketRef.current = io(BASE_URL, {
          withCredentials: true,
          reconnectionAttempts: 5,
          reconnectionDelay: 1000,
          timeout: 10000,
          transports: ["websocket"],
          upgrade: false,
        });
      }

      const socket = socketRef.current;

      const handleConnect = () => {
        console.warn("Connected to server via WebSocket");
        setIsConnected(true);
        socket.emit("subscribe", user.id);
      };

      const handleDisconnect = () => {
        console.warn("Disconnected from server");
        setIsConnected(false);
      };

      const handleConnectError = (error: Error) => {
        console.warn("WebSocket connection error:", error);
        setIsConnected(false);
      };

      const handleNotification = (notification: NotificationsEntity) => {
        setNotifications((prev) => {
          if (prev.length >= LIMIT) {
            prev.pop();
          }
          return [notification, ...prev];
        });
      };

      socket.on("connect", handleConnect);
      socket.on("disconnect", handleDisconnect);
      socket.on("connect_error", handleConnectError);
      socket.on("notification", handleNotification);

      return () => {
        socket.off("connect", handleConnect);
        socket.off("disconnect", handleDisconnect);
        socket.off("connect_error", handleConnectError);
        socket.off("notification", handleNotification);
        if (!user || !user.id) {
          socket.disconnect();
          socketRef.current = null;
        }
      };
    }
  }, [setNotifications, user]);

  const markAsReadByIds = (ids: string[], isMarkAll: boolean = false) => {
    if (isMarkAll) {
      const notificationsChanged = updateObjectsByIds(
        notifications,
        notifications.map((n) => n.id),
        (n: NotificationsEntity) => ({ ...n, readAt: moment().toDate() }),
      );
      setNotifications([...notificationsChanged]);
      setCountToRead(0);
    } else {
      const notificationsChanged = updateObjectsByIds(
        notifications,
        ids,
        (n: NotificationsEntity) => ({ ...n, readAt: moment().toDate() }),
      );
      setNotifications([...notificationsChanged]);
      setCountToRead((prevState) => prevState - 1);
    }
  };
  return (
    <>
      <IconButton onClick={handleClick}>
        <Badge badgeContent={countToRead}>
          <SvgIcon
            name="bell"
            width={24}
            height={24}
            style={{ color: COLORS.primary.darkGreen }}
          />
        </Badge>
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        style={{ marginTop: 10 }}>
        <Notifications
          list={notifications}
          markAsReadByIds={markAsReadByIds}
          requestState={requestState}
          changePage={changePage}
          countToRead={countToRead}
        />
      </Popover>
    </>
  );
};

export default NotificationsWithIcon;

interface Props extends BadgeProps {}

type ClassNames = "root";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));
