import { BadgeProps, Box, Theme } from "@mui/material";
import { Dispatch } from "@reduxjs/toolkit";
import clsx from "clsx";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { useApiRequest } from "../../../../../../../hooks/useApiRequest";
import { useIsCustomer } from "../../../../../../../hooks/useIsCustomer";
import { logout } from "../../../../../../../middleware/api/auth/stateEntity/actions";
import { StateAuth } from "../../../../../../../middleware/api/auth/stateEntity/reducer";
import { API_ENDPOINTS } from "../../../../../../../middleware/api/endpoints.api";
import { getAvatarPathFromFileName } from "../../../../../../../middleware/utils";
import { AppState } from "../../../../../../../store/rootReducer";
import { COLORS } from "../../../../../../../theme/colors";
import Avatar from "../../../../../../atoms/Avatar";
import Button from "../../../../../../atoms/Button";
import Menu from "../../../../../../atoms/Menu";
import MenuItem from "../../../../../../atoms/MenuItem";
import SvgIcon from "../../../../../../atoms/SvgIcon";
import Typography from "../../../../../../atoms/Typography";
import { PathPage } from "../../../../../../pages/pathPage";

const menuListCustomer = {
  items: [
    {
      label: "Profile",
      page: PathPage.Settings,
    },
    {
      label: "Account Details",
      page: PathPage.AccountDetails,
    },
    // {
    //   label: "Manage Users",
    //   page: PathPage.ManageUsers,
    // },
    {
      label: "Logout",
    },
  ],
};

const menuListAdmin = {
  items: [
    {
      label: "Profile",
      page: PathPage.Profile,
    },
    {
      label: "Logout",
    },
  ],
};

const MenuAccount: FC<Props> = ({ children, ...rest }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isCustomer = useIsCustomer();
  const { user } = useSelector<AppState, StateAuth>((state) => state.auth);

  const srcAvatar = useMemo(() => {
    return getAvatarPathFromFileName(user?.avatarFileName);
  }, [user?.avatarFileName]);

  return (
    <Box style={{ display: "flex", alignItems: "center" }}>
      <Typography
        variant="h4"
        style={{
          color: COLORS.primary.darkGreen,
          marginRight: 12,
          textTransform: "initial",
          width: "max-content",
        }}>
        {user?.firstName} {user?.lastName}
      </Typography>
      <Button
        type="submit"
        className={classes.menuButton}
        endIcon={
          <Box display="flex" alignItems="center">
            <Avatar className={classes.avatar} src={srcAvatar} />
            <SvgIcon
              name="chevronDown"
              className={classes.iconChevronDown}
              style={{
                transform: open ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          </Box>
        }
        typeClassName="ghost"
        onClick={handleClick}
      />
      <BoxMenu
        open={open}
        handleClose={handleClose}
        anchorEl={anchorEl}
        menuList={isCustomer ? menuListCustomer : menuListAdmin}
      />
    </Box>
  );
};

const BoxMenu = ({ open, handleClose, anchorEl, menuList }: any) => {
  const { classes } = useStyles();
  const dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();

  const [, postSendRequest] = useApiRequest<any, any>(
    API_ENDPOINTS.AUTH.LOGOUT,
    "POST",
  );

  const handleMenuAction = async (page: PathPage | undefined) => {
    if (page) {
      navigate(page);
    } else {
      void postSendRequest().catch();
      setTimeout(() => {
        dispatch(logout());
      }, 0);
    }
  };

  return (
    <Menu
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      style={{ right: 45 }}
      classes={{ paper: classes.menu }}>
      {menuList.items.map(({ label, page }: any, key: number) => (
        <MenuItem
          key={key}
          className={classes.menuItem}
          onClick={() => handleMenuAction(page)}>
          <Typography
            variant="h6"
            className={clsx(classes.navLink, classes.navLinkMenu)}>
            {label}
          </Typography>
        </MenuItem>
      ))}
    </Menu>
  );
};

export default MenuAccount;

interface Props extends BadgeProps {}

type ClassNames =
  | "root"
  | "menuButton"
  | "avatar"
  | "iconChevronDown"
  | "menu"
  | "menuItem"
  | "navLink"
  | "navLinkMenu";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  menuItem: {
    // cursor: "initial",
    // "&:hover": {
    //   background: "unset",
    //   cursor: "initial",
    // },
    padding: "6px 16px",
  },
  menu: {
    minWidth: 154,
    borderRadius: 0,
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.10)",

    "& .MuiMenu-list": {
      padding: 0,
    },
  },
  navLink: {
    ...(theme.typography.body1 as any),
    color: COLORS.primary.grey40,
    textTransform: "capitalize",
    // "&:hover": {
    //   color: COLORS.primary.white,
    // },
    textDecoration: "none",
  },
  navLinkMenu: {
    color: COLORS.primary.darkGreen,

    // "&:hover": {
    //   color: COLORS.primary.green,
    //   background: "unset",
    // },
  },
  menuButton: {
    "&:hover": {
      "& > *": {
        color: "initial",
      },
      "& svg": {
        color: "initial",
      },
    },
  },
  avatar: {
    width: 40,
    height: 40,
    marginRight: 4,
    color: "initial",
  },
  iconChevronDown: {
    width: 18,
    height: 18,
    color: COLORS.primary.darkGreen,
  },
}));
