import { Components } from "@mui/material";

import MuiButton from "./MuiButton";
import MuiTypography from "./MuiTypography";

const components: Components = {
  MuiButton,
  MuiTypography,
};

export default components;
