import { yupResolver } from "@hookform/resolvers/yup";
import React, { ComponentProps, createContext, FC } from "react";

import useForm from "../../atoms/@form/useForm";
import { validationSchema } from "./form";

interface Props extends ComponentProps<any> {}

interface Context {
  mainForm: any;
}

export const PageContext = createContext<Context>({
  mainForm: {},
} as Context);

const PageContextProvider: FC<Props> = ({ children }) => {
  const mainForm = useForm({
    defaultValues: {
      // settings: [],
      avatars: [],
      firstName: "",
      lastName: "",
      title: "",
    },
    resolver: yupResolver(validationSchema),
  });

  return (
    <PageContext.Provider
      value={{
        mainForm,
      }}>
      {children}
    </PageContext.Provider>
  );
};
export default PageContextProvider;
