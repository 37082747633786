import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

const reactPlugin = new ReactPlugin();
let appInsights: ApplicationInsights | null = null;

const instrumentationKey =
  process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY ||
  "YOUR_INSTRUMENTATION_KEY_HERE";

const isValidInstrumentationKey = (key: string) => {
  return key.length > 0 && key !== "YOUR_INSTRUMENTATION_KEY_HERE";
};

const filteredCodes = [400, 401, 404];

if (isValidInstrumentationKey(instrumentationKey)) {
  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: instrumentationKey,
      extensions: [reactPlugin],
      enableAutoRouteTracking: true,
      enableUnhandledPromiseRejectionTracking: true,
      disableAjaxTracking: false,
      autoTrackPageVisitTime: true,
      disableFetchTracking: false,
    },
  });

  appInsights.loadAppInsights();
  appInsights.trackPageView();

  appInsights.addTelemetryInitializer((envelope) => {
    if (envelope.baseData && envelope.baseData.responseCode) {
      const responseCode = parseInt(envelope.baseData.responseCode);
      if (filteredCodes.includes(responseCode)) {
        return false;
      }
    }
    return true;
  });
} else {
  console.warn(
    "Application Insights is disabled due to invalid or missing instrumentation key.",
  );
}

const trackEvent = (eventName: string, properties?: { [key: string]: any }) => {
  if (appInsights) {
    appInsights.trackEvent({ name: eventName }, properties);
  }
};

const trackException = (error: Error, info: React.ErrorInfo) => {
  if (appInsights) {
    appInsights.trackException({
      exception: error,
      properties: {
        componentStack: info.componentStack,
      },
    });
  }
};

export { reactPlugin, appInsights, trackEvent, trackException };
