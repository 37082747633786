import { Theme } from "@mui/material";
import React, { ComponentProps, FC } from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useController } from "react-hook-form";
import { makeStyles } from "tss-react/mui";

import FormHelperText from "../@form/FormHelperText";

const Captcha: FC<Props> = ({
  form: {
    formState: { errors, isSubmitting },
    register,
    control,
  },
}: any) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name: "recaptcha",
    control,
  });

  return (
    <>
      <GoogleReCaptcha
        onVerify={field.onChange}
        refreshReCaptcha={isSubmitting}
      />
      {errors.recaptcha && (
        <FormHelperText>{errors.recaptcha.message}</FormHelperText>
      )}
    </>
  );
};

export default Captcha;

interface Props extends ComponentProps<any> {}

type ClassNames = "root";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {},
}));
