import { MenuItem as MUIMenuItem, MenuItemProps, Theme } from "@mui/material";
import clsx from "clsx";
import React, { FC } from "react";
import { makeStyles } from "tss-react/mui";

const MenuItem: FC<Props> = ({ className, ...rest }) => {
  const { classes } = useStyles();
  return (
    <MUIMenuItem
      className={clsx(classes.root, className)}
      disableRipple
      {...rest}
    />
  );
};

export default MenuItem;

interface Props extends MenuItemProps {}

type ClassNames = "root";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {},
}));
