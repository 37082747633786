import { Box } from "@mui/material";
import * as React from "react";
import { FC, Suspense } from "react";

import PortalHeader from "../../atoms/PortalHeader";
import Typography from "../../atoms/Typography";

const Content = React.lazy(() => import("./Content"));

const PageSubscriptions: FC<Props> = (props: Props) => {
  return (
    <>
      <Header />
      <Suspense fallback={<></>}>
        <Content />
      </Suspense>
    </>
  );
};

const Header = () => {
  return (
    <PortalHeader>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}>
        <Typography variant="h2">Subscriptions</Typography>
      </Box>
    </PortalHeader>
  );
};

export default PageSubscriptions;

interface Props {}
