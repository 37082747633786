import { Box } from "@mui/material";
import * as React from "react";
import { FC, Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { StateAuth } from "../../../middleware/api/auth/stateEntity/reducer";
import { AppState } from "../../../store/rootReducer";
import PortalHeader from "../../atoms/PortalHeader";
import Typography from "../../atoms/Typography";
import { usePackageAndPeriod } from "../PageSubscriptions/hooks/usePackageAndPeriod";
import { PathPage } from "../pathPage";

const Content = React.lazy(() => import("./Content"));

const PageDashboard: FC<Props> = (props: Props) => {
  const navigate = useNavigate();

  const { packageId, period } = usePackageAndPeriod();
  useEffect(() => {
    if (packageId && period) {
      navigate(PathPage.Subscriptions, { state: { packageId, period } });
    }
  }, [navigate, packageId, period]);

  return (
    <>
      <Header />
      <Suspense fallback={<></>}>
        <Content />
      </Suspense>
    </>
  );
};

const Header = () => {
  const { user } = useSelector<AppState, StateAuth>((state) => state.auth);

  return (
    <PortalHeader>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}>
        <Typography variant="h2">Welcome, {user?.firstName}</Typography>
      </Box>
    </PortalHeader>
  );
};

export default PageDashboard;

interface Props {}
