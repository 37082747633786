import { TypographyOptions } from "@mui/material/styles/createTypography";

import { COLORS } from "./colors";

export const FONT_SIZES = {
  font: {
    size: {
      xxs: 10,
      xs: 12,
      s: 14,
      m: 15,
      l: 16,
      xl: 20,
      xxl: 26,
    },
  },
};

export const TYPOGRAPHY_OPTIONS: TypographyOptions = {
  fontWeightRegular: "400",
  fontWeightBold: "700",
  fontFamily: ["IBM Plex Serif", "IBM Plex Sans"].join(","),
  h1: {
    color: COLORS.primary.darkGreen,
    fontFamily: "IBM Plex Serif",
    fontSize: FONT_SIZES.font.size.xxl,
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  h2: {
    color: COLORS.primary.darkGreen,
    fontFamily: "IBM Plex Serif",
    fontSize: FONT_SIZES.font.size.xl,
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  h3: {
    color: COLORS.primary.darkGreen,
    fontFamily: "IBM Plex Serif",
    fontSize: FONT_SIZES.font.size.l,
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  h4: {
    color: COLORS.primary.green,
    fontFamily: "IBM Plex Sans",
    fontSize: FONT_SIZES.font.size.m,
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
  h5: {
    color: COLORS.primary.green,
    fontFamily: "IBM Plex Sans",
    fontSize: FONT_SIZES.font.size.s,
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
  h6: {
    color: COLORS.primary.grey50,
    fontFamily: "IBM Plex Sans",
    fontSize: FONT_SIZES.font.size.s,
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
  subtitle1: {
    color: COLORS.primary.darkGreen,
    fontFamily: "IBM Plex Serif",
    fontSize: FONT_SIZES.font.size.m,
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "1",
  },
  body1: {
    color: COLORS.primary.dark,
    fontFamily: "IBM Plex Sans",
    fontSize: FONT_SIZES.font.size.m,
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
  body2: {
    color: COLORS.primary.dark,
    fontFamily: "IBM Plex Sans",
    fontSize: FONT_SIZES.font.size.m,
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "20px",
  },
  button: {
    color: COLORS.primary.grey50,
    fontFamily: "IBM Plex Sans",
    fontSize: FONT_SIZES.font.size.s,
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
  caption: {
    color: COLORS.primary.grey50,

    fontFamily: "IBM Plex Sans",
    fontSize: FONT_SIZES.font.size.xs,
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
  overline: {
    color: COLORS.primary.grey50,
    fontFamily: "IBM Plex Sans",
    fontSize: FONT_SIZES.font.size.s,
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
    textTransform: "none",
  },
};
