import { UserNotificationTypeEnum } from "../../@types/user.type";

export enum NotificationsTypeEnum {
  TICKET_RESOLVED = "ticket_resolved",
  TICKET_REQUESTED = "ticket_requested",
  NEW_OFFICE_ADDED = "new_office_added",
}

export interface UpdateSubscriptionsOfficesDto {
  officesIds: number[];
  type: UserNotificationTypeEnum;
}
