import axios, { HttpStatusCode } from "axios";
import i18next from "i18next";

import { store } from "../store/store";
import { logout } from "./api/auth/stateEntity/actions";
import { API_ENDPOINTS, BASE_URL } from "./api/endpoints.api";
import { http } from "./http";

export const isErrorUnauthorized = (status: number) =>
  status === HttpStatusCode.Unauthorized;

export const isLoginEndpoint = (url: string) =>
  url === API_ENDPOINTS.AUTH.LOGIN;

export const isErrorForbidden = (status: number) =>
  status === HttpStatusCode.Forbidden;

export const isError5xx = (status: number) =>
  status >= HttpStatusCode.InternalServerError;

export const isError422 = (status: number) =>
  status == HttpStatusCode.UnprocessableEntity;
export const isError401 = (status: number) =>
  status == HttpStatusCode.Unauthorized;
export const isError400 = (status: number) =>
  status == HttpStatusCode.BadRequest;
export const isError429 = (status: number) =>
  status == HttpStatusCode.TooManyRequests;

export const isError408 = (status: number) =>
  status == HttpStatusCode.RequestTimeout;
export const isBlacklisted = (status: number, message: string) =>
  status === HttpStatusCode.BadRequest && message === "isBlacklisted";

export const get422ErrorMessage = (error: any) => {
  const keyToCheck = `errors.${error}`;
  if (!i18next.exists(keyToCheck)) return;
  return i18next.t([`errors.${error}`, error]);
};

export const unauthorizedFlow = async (
  originalRequest: any,
  showErrorAlert: any,
) => {
  originalRequest._retry = true;
  try {
    originalRequest.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "refreshToken",
    )}`;

    const response = await axios.post(
      originalRequest.baseURL + API_ENDPOINTS.AUTH.REFRESH,
      {},
      {
        headers: originalRequest.headers,
      },
    );
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("refreshToken", response.data.refreshToken);
    return http(originalRequest);
  } catch (error: any) {
    store.dispatch(logout());
  }
};

export const blacklistedFlow = async (showErrorAlert: any) => {
  showErrorAlert(i18next.t("errors.isBlacklisted"));
  store.dispatch(logout());
  return Promise.reject();
};

export const getAvatarPathFromFileName = (filename: string | undefined) => {
  return filename ? `${BASE_URL}/public/avatars/${filename}` : "";
};

export const getImagePath = (path: string | undefined) => {
  return path ? `${BASE_URL}/public/${path}` : "";
};
