import { BaseTextFieldProps } from "@mui/material/TextField/TextField";
import { AsYouType, parsePhoneNumber } from "libphonenumber-js";
import React, { ChangeEvent, forwardRef, useRef, useState } from "react";
import { Control } from "react-hook-form";

import TextField from "../../atoms/@form/TextField";

interface Props extends BaseTextFieldProps {
  onChange: (value: string) => void;
  onBlur: () => void;
  value: string;
  name: string;
  error?: boolean;
  helperText?: string;
  control: Control<any>;
}

const TextFieldPhone = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { onChange, onBlur, value, name, error, helperText } = props;
  const [displayValue, setDisplayValue] = useState(value);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const formatPhoneNumber = (input: string): string => {
    try {
      const phoneNumber = parsePhoneNumber(input);

      if (phoneNumber && phoneNumber.isValid()) {
        return phoneNumber.format("INTERNATIONAL");
      }
    } catch (error) {
      const formatter = new AsYouType();
      formatter.input(input);
      const formattedNumber = formatter.getNumber();
      if (formattedNumber) {
        return formattedNumber.format("INTERNATIONAL");
      }
    }

    return input;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target;
    const selectionStart = input.selectionStart || 0;
    const rawValue = input.value;
    const formattedValue = formatPhoneNumber(rawValue);

    const newCursorPosition =
      selectionStart + (formattedValue.length - rawValue.length);

    setDisplayValue(formattedValue);
    onChange(formattedValue);

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.setSelectionRange(
          newCursorPosition,
          newCursorPosition,
        );
      }
    }, 0);
  };

  return (
    <TextField
      inputRef={(el: HTMLInputElement | null) => {
        inputRef.current = el;
        if (typeof ref === "function") ref(el);
        else if (ref) ref.current = el;
      }}
      id="phone"
      label="Phone"
      type="tel"
      error={error}
      helperText={helperText}
      {...props}
      value={displayValue}
      onChange={handleChange}
      onBlur={onBlur}
      name={name}
      placeholder={"+1 20220202020"}
    />
  );
});

TextFieldPhone.displayName = "TextFieldPassword";

export default TextFieldPhone;
