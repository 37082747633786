import { Button as MUIButton, ButtonProps, Theme } from "@mui/material";
import clsx from "clsx";
import React, { FC } from "react";
import { makeStyles } from "tss-react/mui";

import { COLORS } from "../../../theme/colors";
import { SPACING } from "../../../theme/spacing";
import CircularProgress from "../CircularProgress";

const Button: FC<Props> = ({
  children,
  typeClassName = "primary",
  isSpinner = false,
  className,
  disabled,
  ...rest
}) => {
  const { classes } = useStyles();

  return (
    <MUIButton
      className={clsx(classes[typeClassName], className)}
      type="button"
      disableRipple
      {...rest}
      disabled={isSpinner || disabled}>
      {children}
      {isSpinner && <CircularProgress isCenterFlex />}
    </MUIButton>
  );
};

export default Button;

interface Props extends ButtonProps {
  typeClassName?: ClassNames;
  isSpinner?: boolean;
}

type ClassNames =
  | "primary"
  | "secondary"
  | "secondaryRed"
  | "ghost"
  | "tertiaryPrimary"
  | "tertiarySecondary"
  | "tertiaryGhost"
  | "delete"
  | "table"
  | "group";

const common = {
  transition: "none",
  "& .MuiButton-endIcon": {
    marginLeft: SPACING.s_2,
    marginRight: 0,
  },
};

const primary = {
  backgroundColor: COLORS.primary.green,
  color: COLORS.primary.white,

  "& svg": {
    color: COLORS.primary.white,
  },

  "& .MuiCircularProgress-root": {
    width: `${SPACING.s_20}px !important`,
    height: `${SPACING.s_20}px !important`,
    marginLeft: SPACING.s_8,
  },

  "&:hover": {
    backgroundColor: COLORS.primary.lightGreen,
  },
  "&:active": {
    backgroundColor: COLORS.secondary.green,
  },
  "&.Mui-disabled": {
    backgroundColor: COLORS.primary.grey40,
    color: COLORS.primary.white,
  },
};
const secondary = {
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: COLORS.primary.green,
  color: COLORS.primary.green,
  backgroundColor: "transparent",

  "& svg": {
    color: COLORS.primary.green,
  },

  "& .MuiCircularProgress-root": {
    width: `${SPACING.s_20}px !important`,
    height: `${SPACING.s_20}px !important`,
    marginLeft: SPACING.s_8,
  },

  "&:hover": {
    borderColor: COLORS.primary.lightGreen,
    color: COLORS.primary.lightGreen,
    backgroundColor: "transparent",
    "& svg": {
      color: COLORS.primary.lightGreen,
    },
  },
  "&:active": {
    borderColor: COLORS.secondary.green,
    color: COLORS.secondary.green,
    backgroundColor: "transparent",

    "& svg": {
      color: COLORS.secondary.green,
    },
  },
  "&.Mui-disabled": {
    borderColor: COLORS.primary.grey40,
    color: COLORS.primary.grey40,

    "& svg": {
      color: COLORS.primary.grey40,
    },
  },
};
const ghost = {
  color: COLORS.primary.green,
  padding: 0,
  margin: 0,
  minWidth: "unset",
  "&:hover": {
    color: COLORS.primary.lightGreen,
    backgroundColor: "unset",

    "& svg": {
      color: COLORS.primary.lightGreen,
    },
  },
  "&:active": {
    color: COLORS.secondary.green,
    backgroundColor: "unset",

    "& svg": {
      color: COLORS.secondary.green,
    },
  },
  "&.Mui-disabled": {
    color: COLORS.primary.grey40,
    backgroundColor: "unset",

    "& svg": {
      color: COLORS.primary.grey40,
    },
  },
};

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  primary: {
    ...common,
    ...primary,
    textTransform: "uppercase",
    height: "40px",
    padding: "12px 32px",
    borderRadius: "99px",

    "& .MuiButton-endIcon svg": {
      width: 16,
      height: 16,
    },
  },
  secondary: {
    ...common,
    ...secondary,
    textTransform: "uppercase",
    height: "40px",
    padding: "12px 32px",
    borderRadius: "99px",

    "& .MuiButton-endIcon svg": {
      width: 24,
      height: 24,
    },
  },
  secondaryRed: {
    ...common,
    ...secondary,
    textTransform: "uppercase",
    height: "40px",
    padding: "8px 16px",
    borderRadius: "99px",

    "& .MuiButton-endIcon svg": {
      width: 18,
      height: 18,
    },
    borderColor: COLORS.secondary.red,
    color: COLORS.secondary.red,

    "& svg": {
      color: COLORS.secondary.red,
    },

    "&:hover": {
      borderColor: COLORS.secondary.darkRed,
      color: COLORS.secondary.darkRed,
      backgroundColor: "transparent",
      "& svg": {
        color: COLORS.secondary.darkRed,
      },
    },
    "&:active": {
      borderColor: COLORS.secondary.red,
      color: COLORS.secondary.red,
      backgroundColor: "transparent",

      "& svg": {
        color: COLORS.secondary.red,
      },
    },
  },
  ghost: {
    ...common,
    ...ghost,
    textTransform: "uppercase",
    "& .MuiButton-endIcon svg": {
      width: 18,
      height: 18,
    },
  },
  tertiaryPrimary: {
    ...common,
    ...primary,
    textTransform: "capitalize",
    height: "36px",
    padding: "12px 16px",
    borderRadius: "99px",

    "& .MuiButton-endIcon svg": {
      width: 18,
      height: 18,
    },
  },
  tertiarySecondary: {
    ...common,
    ...secondary,
    textTransform: "capitalize",
    height: "36px",
    padding: "12px 16px",
    borderRadius: "99px",

    "& .MuiButton-endIcon svg": {
      width: 18,
      height: 18,
    },
  },
  tertiaryGhost: {
    ...common,
    ...ghost,
    textTransform: "capitalize",
  },
  delete: {
    ...common,
    textTransform: "uppercase",
    height: "40px",
    padding: "12px 32px",
    borderRadius: "99px",
    backgroundColor: COLORS.secondary.red,
    color: COLORS.primary.white,

    "&:hover": {
      backgroundColor: COLORS.secondary.lightRed20,
    },
    "&:active": {
      backgroundColor: COLORS.secondary.darkRed,
    },
    "&.Mui-disabled": {
      backgroundColor: COLORS.primary.grey40,
      color: COLORS.primary.white,
    },
    "& .MuiButton-endIcon svg": {
      width: 18,
      height: 18,
    },
  },
  table: {
    ...common,
    height: "36px",
    padding: "8px 12px",
    borderRadius: "4px",
    textTransform: "unset",

    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: COLORS.primary.grey30,
    color: COLORS.primary.dark,
    backgroundColor: COLORS.primary.white,
    "&:hover": {
      backgroundColor: COLORS.primary.grey10,
      borderColor: COLORS.primary.grey30,
    },
    "&:active": {
      backgroundColor: COLORS.primary.grey20,
      borderColor: COLORS.primary.grey30,
    },
    "&.active": {
      backgroundColor: COLORS.primary.grey20,
      borderColor: COLORS.primary.grey30,
    },
    "& .MuiButton-endIcon svg": {
      width: 18,
      height: 18,
    },

    "& .MuiCircularProgress-root": {
      width: `${SPACING.s_20}px !important`,
      height: `${SPACING.s_20}px !important`,
      marginLeft: SPACING.s_8,
    },
  },
  group: {
    ...(theme.typography.overline as any),
    color: COLORS.primary.dark,

    display: "flex",
    height: "36px",
    padding: "8px 12px",
    alignItems: "center",
    gap: "36px",

    // borderRadius: "4px 0px 0px 4px",
    // border: "1px solid var(--primary-grey-30-e-2-e-2-e-2, #E2E2E2)",
    borderColor: COLORS.primary.grey10,
    background: COLORS.primary.white,

    "&:hover": {
      // borderColor: DOMAIN_COLORS.primary.grey30,
      borderColor: "transparent",
      backgroundColor: COLORS.primary.grey20,
    },

    "&:active": {
      // borderColor: DOMAIN_COLORS.primary.grey30,
      borderColor: "transparent",
      backgroundColor: COLORS.primary.grey20,
    },
  },
}));
