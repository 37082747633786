import "./recaptcha.css"; // Import the CSS file

import { Box, Theme } from "@mui/material";
import React, { FC, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { useIsCustomer } from "../../../hooks/useIsCustomer";
import { COLORS } from "../../../theme/colors";
import { SPACING } from "../../../theme/spacing";
import Sidebar from "../../organisms/Sidebar";
import { NavigationListItem } from "../../organisms/Sidebar/sidebar.type";
import { PathPage } from "../../pages/pathPage";
import Header from "./components/Header";

const NAVIGATION_CUSTOMER: NavigationListItem[] = [
  { name: "Dashboard", icon: "gaugeMax", page: PathPage.Overview },
  { name: "Subscriptions", icon: "packages", page: PathPage.Subscriptions },
  { name: "My Data", icon: "fileList", page: PathPage.MyData },
  // { name: "Manage Users", icon: "users", page: PathPage.ManageUsers },
  { name: "My Requests", icon: "messageDots", page: PathPage.MyRequests },
  { name: "Resources", icon: "bookBookmark", page: PathPage.Resources },
  { name: "Settings", icon: "gear", page: PathPage.Settings },
  { name: "Support", icon: "lifeRing", page: PathPage.Support },
];

const NAVIGATION_ADMIN: NavigationListItem[] = [
  { name: "Overview", icon: "gaugeMax", page: PathPage.Overview },
  // { name: "Packages", icon: "packages" },
  // { name: "Admins", icon: "users" },
  // { name: "Master Data", icon: "list" },
  { name: "Users", icon: "user", page: PathPage.Users },
  // { name: "Requests", icon: "messageDots" },
  // { name: "Flagged Firms", icon: "flag" },
  // { name: "Industries", icon: "suitcase" },
  // { name: "Settings", icon: "gear" },
];

const LayoutMain: FC<Props> = ({ className }: Props) => {
  const { classes } = useStyles();

  const isCustomer = useIsCustomer();

  useEffect(() => {
    document.body.style.backgroundColor = COLORS.primary.creme;

    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []); // Empty dependency array means this runs once on mount and once on unmount

  return (
    <Box className={classes.root}>
      <Sidebar
        navigationList={isCustomer ? NAVIGATION_CUSTOMER : NAVIGATION_ADMIN}
      />
      <Box component="main" className={classes.main}>
        <Header />
        <Box display="flex" marginTop={`${SPACING.s_48}px`}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default LayoutMain;

interface Props {
  className?: string;
}

type ClassNames = "root" | "main";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    display: "flex",
    minHeight: "130vh",
    height: "100%",
    maxWidth: theme.breakpoints.values.breakpoint1,
    minWidth: theme.breakpoints.values.md,
  },
  main: {
    display: "flex",
    flexGrow: 1,
    padding: "35px 40px",
    flexDirection: "column",
  },
}));
