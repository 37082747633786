import {
  DialogActions as MUIDialogActions,
  DialogActionsProps,
  Theme,
} from "@mui/material";
import clsx from "clsx";
import * as React from "react";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";

import { SPACING } from "../../../theme/spacing";

const DialogActions: FC<Props> = ({ children, className, ...rest }) => {
  const { classes } = useStyles();
  return (
    <MUIDialogActions className={clsx(classes.root, className)} {...rest}>
      {children}
    </MUIDialogActions>
  );
};

export default DialogActions;

interface Props extends DialogActionsProps {}

type ClassNames = "root";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    padding: SPACING.s_48,
    // justifyContent: "space-between",
    "& button": {
      marginLeft: SPACING.s_24,
    },
    "& >:not(style)~:not(style)": {
      marginLeft: SPACING.s_24,
    },
  },
}));
