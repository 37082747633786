export enum ZIndexType {
  default = 0,

  aboveDefault = 1,
  popover = 20,
  popper = 21,
  popper1 = 22,
  popper2 = 23,
  dialog = 30,
  alerts = 1400,
}
