import "@mui/material/styles";

import { ThemeOptions } from "@mui/material";
import createTheme from "@mui/material/styles/createTheme";

import components from "./components";
import palette from "./palette";
import { TYPOGRAPHY_OPTIONS } from "./typography";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
    breakpoint1: true;
  }
}

const themeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 992,
      xl: 1280,
      xxl: 1440,
      breakpoint1: 1600,
    },
  },
  palette,
  components,
  typography: TYPOGRAPHY_OPTIONS,
};

export default createTheme(themeOptions);
