import { useCallback, useEffect, useRef, useState } from "react";

import { useApiRequest } from "../../../../hooks/useApiRequest";
import { API_ENDPOINTS } from "../../../../middleware/api/endpoints.api";
import { ISavedSearch } from "../../../../middleware/api/offices/types";

export function useHookListSavedSearch(isInit: boolean) {
  const [countOffices, setCountOffices] = useState<number>(0);
  const [initialOffices, setInitialOffices] = useState<any>([]);
  const [rows, setRows] = useState<any[]>([]);

  const [requestState, sendRequest] = useApiRequest<ISavedSearch[], any>(
    API_ENDPOINTS.OFFICES.SEARCH_SAVED,
    "GET",
  );

  const requestSentRef = useRef(false);

  const getRequests = useCallback((): Promise<ISavedSearch[]> => {
    return sendRequest()
      .then((data) => {
        if (data) {
          setRows(data);
          return Promise.resolve(data);
        }
        return Promise.resolve([]);
      })
      .catch((error) => {
        console.error("Error fetching requests:", error);
        return Promise.resolve([]);
      });
  }, [sendRequest]);

  const init = useCallback(() => {
    getRequests().then((data) => {
      if (data && data.length) {
        setCountOffices(data.length);
        setInitialOffices(data);
      }
    });
  }, [getRequests]);

  useEffect(() => {
    if (!isInit) return;
    if (requestSentRef.current) return;
    requestSentRef.current = true;
    init();
  }, [
    setInitialOffices,
    getRequests,
    requestState,
    requestState.data,
    sendRequest,
    setCountOffices,
    isInit,
    init,
  ]);

  return {
    listSavedSearch: {
      countOffices,
      setCountOffices,
      initialOffices,
      setInitialOffices,
      getSearch: getRequests,
      requestState,
      rows,
      setRows,
      init,
    },
  };
}
