import { Avatar as MUIAvatar, AvatarProps, Theme } from "@mui/material";
import clsx from "clsx";
import React, { FC } from "react";
import { makeStyles } from "tss-react/mui";

import { COLORS } from "../../../theme/colors";

const Avatar: FC<Props> = ({ children, className, selected, ...rest }) => {
  const { classes } = useStyles();
  return (
    <MUIAvatar
      className={clsx(
        classes.root,
        className,
        selected ? classes.selected : {},
      )}
      {...rest}>
      {children}
    </MUIAvatar>
  );
};

export default Avatar;

interface Props extends AvatarProps {
  selected?: boolean;
}

type ClassNames = "root" | "selected";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    "& .MuiAvatar-fallback": {
      color: `${COLORS.primary.white} !important`,
    },
  },
  selected: {
    border: `solid 5px ${COLORS.primary.lightGreen}`,
  },
}));
