import { Radio as MUIRadio, RadioProps, Theme } from "@mui/material";
import React, { FC } from "react";
import { makeStyles } from "tss-react/mui";

import { COLORS } from "../../../../theme/colors";
import { SPACING } from "../../../../theme/spacing";

const Radio: FC<Props> = ({ ...rest }) => {
  const { classes } = useStyles();
  return (
    <MUIRadio
      disableRipple
      icon={<></>}
      checkedIcon={<div className={classes.checked}></div>}
      classes={{ root: classes.root }}
      {...rest}
    />
  );
};

export default Radio;

interface Props extends RadioProps {}

type ClassNames = "root" | "checked";

export const radioHover = {
  border: `1px solid ${COLORS.primary.orange}`,
};
const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    minWidth: 30,
    minHeight: 30,
    maxWidth: 30,
    maxHeight: 30,
    borderRadius: "50%",
    padding: 0,
    marginRight: SPACING.s_12,
    border: `1px solid ${COLORS.primary.grey40}`,

    "&:hover": {
      ...radioHover,
    },

    "&.Mui-disabled": {
      border: `1px solid ${COLORS.primary.grey40}`,
      backgroundColor: COLORS.primary.grey20,
    },

    "&.Mui-checked": {
      border: `1px solid ${COLORS.primary.orange}`,
      color: COLORS.primary.orange,
      backgroundColor: COLORS.primary.orange,
    },

    "&.MuiRadio-sizeSmall": {
      width: 20,
      height: 20,

      "&:hover": {
        border: `1px solid ${COLORS.primary.green}`,
      },

      "&.Mui-checked": {
        border: `1px solid ${COLORS.primary.green}`,
        color: COLORS.primary.green,
        backgroundColor: COLORS.primary.green,
      },
    },
  },
  checked: {
    borderRadius: "50%",
    backgroundColor: COLORS.primary.white,

    '&[font-size="small"]': {
      width: 8,
      height: 8,
    },

    '&[font-size="medium"]': {
      width: 16,
      height: 16,
    },
  },
}));
