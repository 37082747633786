import { Box } from "@mui/material";
import React from "react";

interface RadioGroupItemProps {
  children: React.ReactNode;
  onClick: () => void;
}

const RadioGroupItem: React.FC<RadioGroupItemProps> = ({
  children,
  onClick,
}) => {
  return (
    <Box
      onClick={onClick}
      style={{
        display: "flex",
        alignItems: "flex-start",
        marginTop: 30,
        cursor: "pointer", // This makes it clear the item is clickable
      }}>
      {children}
    </Box>
  );
};

export default RadioGroupItem;
