import { array, object, string } from "yup";

export const validationSchema = object().shape({
  // position: string().required("Family Type is required"),
  // company: string().required("Company is required"),
  goals: array()
    .min(1, "At least one Goal is required")
    .required("Goal is required"),
  business: string().required("Business is required"),
  businessOther: string(),
  goalsOther: string(),
  // beta: string().required("Company is required"),
  // isConsent: boolean().oneOf([true], "Please accept the terms and conditions"),
  recaptcha: string(),
});
