import { createAsyncThunk } from "@reduxjs/toolkit";

import { http } from "../../../http";
import { API_ENDPOINTS } from "../../endpoints.api";
import { FamilyOfficesEntity } from "../../entities/FamilyOfficesEntity";

export const fetchSearch = createAsyncThunk("offices/fetchSearch", async () => {
  const response = await http.post<FamilyOfficesEntity[]>(
    API_ENDPOINTS.OFFICES.SEARCH,
    {},
  );
  return response.data;
});
