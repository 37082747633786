import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { FamilyOfficesEntity } from "../../entities/FamilyOfficesEntity";
import { fetchSearch } from "./search.thunk";

interface SearchState {
  data: FamilyOfficesEntity[];
  status: "idle" | "loading" | "succeeded" | "failed";
  isLoading: boolean;
  error: string | null;
}

const initialState: SearchState = {
  data: [],
  status: "idle",
  isLoading: false,
  error: null,
};

export const sliceSearch = createSlice({
  name: "search",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSearch.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(
        fetchSearch.fulfilled,
        (state, action: PayloadAction<FamilyOfficesEntity[]>) => {
          state.status = "succeeded";
          state.isLoading = false;
          state.data = action.payload;
        },
      )
      .addCase(fetchSearch.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});
