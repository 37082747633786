import React, { FC } from "react";
import { Helmet } from "react-helmet-async";

const GTM_ID = "GTM-M2NZQ3M";

const GoogleTagManager: FC<any> = () => {
  return (
    <>
      <Helmet>
        <noscript>
          {`
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}"
            height="0"
            width="0"
            style="display:none;visibility:hidden "></iframe>
            `}
        </noscript>
      </Helmet>
    </>
  );
};

export default GoogleTagManager;
