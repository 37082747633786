import {
  Box,
  DialogTitle as MUIDialogTitle,
  DialogTitleProps as MUIDialogTitleProps,
  IconButton as MUIIconButton,
  Theme,
} from "@mui/material";
import * as React from "react";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";

import { ZIndexType } from "../../../@types/zIndex.type";
import { COLORS } from "../../../theme/colors";
import { SPACING } from "../../../theme/spacing";
import SvgIcon from "../SvgIcon";
import Typography from "../Typography";

const DialogTitle: FC<Props> = ({
  TitleBackground,
  caption = {
    title: "",
    subTitle: "",
    description: "",
  },
  onClose,
  children,
}: Props) => {
  const { classes } = useStyles();

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}>
      {!!TitleBackground && (
        <Box className={classes.titleBackgroundWrapper}>
          <TitleBackground className={classes.titleBackground} />
        </Box>
      )}
      <MUIDialogTitle className={classes.title} component={"div"}>
        <Typography variant="h1">{caption.title}</Typography>
        {!!caption.subTitle && (
          <Typography variant="h3" style={{ marginTop: SPACING.s_10 }}>
            {caption.subTitle}
          </Typography>
        )}
        {!!caption.description && (
          <Typography variant="body1" style={{ marginTop: SPACING.s_10 }}>
            {caption.description}
          </Typography>
        )}

        {!!children && children}
      </MUIDialogTitle>
      <MUIIconButton className={classes.closeButton} onClick={onClose}>
        <SvgIcon name="close" width={32} height={32} />
      </MUIIconButton>
    </Box>
  );
};

export default DialogTitle;

export interface DialogTitleCaption {
  title: string;
  subTitle?: string;
  description?: string | null;
  children?: any;
}

interface Props extends MUIDialogTitleProps {
  onClose: any;
  caption: DialogTitleCaption;
  TitleBackground?: any;
}

type ClassNames =
  | "root"
  | "title"
  | "content"
  | "actions"
  | "titleBackground"
  | "titleBackgroundWrapper"
  | "closeButton";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {},
  title: {
    padding: SPACING.s_48,
    display: "flex",
    flexDirection: "column",
    zIndex: ZIndexType.aboveDefault,
  },
  content: {
    padding: SPACING.s_48,
    paddingBottom: 0,
  },
  actions: {
    padding: SPACING.s_48,
    justifyContent: "space-between",
    "& > div > *": {
      marginLeft: SPACING.s_24,
    },
  },
  titleBackground: {
    width: "614px",
    height: "100%",
    position: "absolute",
  },
  titleBackgroundWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: COLORS.primary.creme,
    position: "absolute",
    top: 0,
    width: "100%",
    minHeight: 180,
    height: "100%",
    zIndex: ZIndexType.default,
  },
  closeButton: {
    position: "absolute",
    right: "14px",
    top: "11px",
    zIndex: 2,
    padding: 0,

    "& .MuiSvgIcon-root": {
      color: COLORS.primary.grey40,
    },

    "&:hover": {
      "& .MuiSvgIcon-root": {
        color: COLORS.primary.grey50,
      },
    },
  },
}));
