import _ from "lodash";

export const areAllValuesEmpty = (data: any): boolean => {
  return data.every((item: any) => item.value === 0);
};

export function generateColors(): string {
  const highChannel = Math.floor(Math.random() * 3);

  const highValue = Math.floor(Math.random() * 56) + 200;

  const otherValues = [0, 1].map(() => Math.floor(Math.random() * 101) + 100);

  const color = [0, 0, 0];
  color[highChannel] = highValue;
  color[highChannel !== 0 ? 0 : 1] = otherValues[0];
  color[highChannel !== 2 ? 2 : 1] = otherValues[1];

  return `#${color.map((c) => c.toString(16).padStart(2, "0")).join("")}`;
}

const colorsGenerated = Array.from({ length: 10 }, generateColors);

const colorsDesign = [
  "#9BB7FF",
  "#61AF90",
  "#FFCE51",
  "#68D6F9",
  "#CA5628",
  "#F5A840",
  "#9CD0FF",
];

export const COLORS_CHARTS = _.uniq([...colorsDesign, ...colorsGenerated]);
