import { FONT_SIZES } from "../typography";

export default {
  defaultProps: {
    disableRipple: true,

    root: {
      boxShadow: "none",
      fontSize: FONT_SIZES.font.size.m,
      lineHeight: FONT_SIZES.font.size.xl,

      "&:hover": {
        boxShadow: "none",
      },
    },
  },
};
