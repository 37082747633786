import React, { ComponentProps, createContext, FC, useState } from "react";

import {
  ResearchRequests,
  Tickets,
} from "../../../middleware/api/customerRequests/types";

interface Props extends ComponentProps<any> {}

interface Context {
  researchRequests: any;
  setResearchResearchRequests: any;
  tickets: any;
  setTickets: any;
}

export const PageMyRequestsContext = createContext<Context>({
  researchRequests: [],
  tickets: [],
} as Context);

const PageMyRequestsContextProvider: FC<Props> = ({ children }) => {
  const [researchRequests, setResearchResearchRequests] = useState<
    ResearchRequests[]
  >([]);

  const [tickets, setTickets] = useState<Tickets[]>([]);

  return (
    <PageMyRequestsContext.Provider
      value={{
        researchRequests,
        setResearchResearchRequests,
        tickets,
        setTickets,
      }}>
      {children}
    </PageMyRequestsContext.Provider>
  );
};
export default PageMyRequestsContextProvider;
