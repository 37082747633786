import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { SPACING } from "../../../../theme/spacing";

export type ClassNames = "root" | "form";

export const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {},
  form: {
    "& > *": {
      marginTop: `${SPACING.s_48}px !important`,
    },
    "& > *:first-of-type": {
      marginTop: `0 !important`,
    },
  },
}));
