import { User } from "../../../@types/user.type";
import {
  AuthAction,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
} from "./actions";

export interface StateAuth {
  user: User | null;
  rememberMe: boolean;
  isLoading: boolean;
  error?: string | null;
}

const initialState: StateAuth = {
  rememberMe: false,
  isLoading: false,
  user: null,
};

export default (state = initialState, action: AuthAction): StateAuth => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...state, isLoading: true };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
        error: null,
      };
    case LOGIN_FAILURE:
      return { ...state, isLoading: false, error: action.payload };
    case LOGOUT:
      return { ...state, user: null };
    default:
      return state;
  }
};
