import { useCallback, useEffect, useRef, useState } from "react";

import { useApiRequest } from "../../../../hooks/useApiRequest";
import { API_ENDPOINTS } from "../../../../middleware/api/endpoints.api";
import { FamilyOfficesEntity } from "../../../../middleware/api/entities/FamilyOfficesEntity";
import { useFetchSearch } from "../../../../middleware/api/offices/search/useFetchSearch.hook";

export function useHookListAll(isInit: boolean) {
  const [countOffices, setCountOffices] = useState<number>(0);
  const [rows, setRows] = useState<any[]>([]);
  const [officesIdsExport, setOfficesIdsExport] = useState<any[]>([]);

  const [requestState, sendRequest] = useApiRequest<FamilyOfficesEntity[], any>(
    API_ENDPOINTS.OFFICES.SEARCH,
    "POST",
  );

  const { data: initialOffices, isLoading } = useFetchSearch();

  const requestSentRef = useRef(false);

  const getRequests = useCallback(
    (payload = {}): void => {
      if (Object.keys(payload).length === 0) {
        setRows(initialOffices);
        setCountOffices(initialOffices.length);
      } else {
        sendRequest(payload)
          .then((data) => {
            if (data) {
              setRows(data);
            }
          })
          .catch((error) => {
            console.error("Error fetching requests:", error);
          });
      }
    },
    [initialOffices, sendRequest],
  );

  useEffect(() => {
    if (!isInit) return;
    if (requestSentRef.current) return;
    if (initialOffices.length) {
      requestSentRef.current = true;
      getRequests();
    }
  }, [
    getRequests,
    sendRequest,
    setCountOffices,
    isInit,
    initialOffices.length,
  ]);

  return {
    listAll: {
      countOffices,
      setCountOffices,
      initialOffices,
      getSearch: getRequests,
      requestState,
      rows,
      setRows,
      isLoading: isLoading || requestState.isLoading,
      officesIdsExport,
      setOfficesIdsExport,
    },
  };
}
