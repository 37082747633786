import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Theme } from "@mui/material";
import * as React from "react";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { useApiRequest } from "../../../hooks/useApiRequest";
import { API_ENDPOINTS } from "../../../middleware/api/endpoints.api";
import { SPACING } from "../../../theme/spacing";
import TextField from "../../atoms/@form/TextField";
import useForm from "../../atoms/@form/useForm";
import { AlertContext } from "../../atoms/Alert/AlertContextProvider";
import Button from "../../atoms/Button";
import Captcha from "../../atoms/Captcha";
import Link from "../../atoms/Link";
import Typography from "../../atoms/Typography";
import Authorization from "../../templates/Authorization";
import { PathPage } from "../pathPage";
import { validationSchema } from "./form";

const PageForgotPassword: FC<Props> = (props: Props) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const form = useForm({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = form;

  const { showAlert } = useContext(AlertContext);

  const [requestState, sendRequest] = useApiRequest<any, any>(
    API_ENDPOINTS.AUTH.FORGOT_PASSWORD,
    "POST",
  );

  const onSubmit = (data: any) => {
    sendRequest({ ...data })
      .then(() => {
        showAlert("We have e-mailed your password reset link!", "success");
      })
      .catch((error) => {
        console.error("Error fetching requests:", error);
      });
  };

  return (
    <Authorization>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Box className={classes.sectionTitle}>
          <Typography variant="h1">Forgot Password?</Typography>
          <Typography
            variant="body1"
            style={{ marginTop: 10, display: "flex" }}>
            Enter the email address associated with your account and we’ll send
            you a link to reset your password
          </Typography>
        </Box>
        <Box className={classes.sectionAboutContent}>
          <TextField
            style={{ marginTop: "0" }}
            id="email"
            label="Work Email"
            variant="standard"
            type="email"
            {...register("email")}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          <Typography variant="overline" style={{ marginTop: SPACING.s_60 }}>
            Back to
            <Link
              variant="overline"
              component={RouterLink}
              to={PathPage.SignIn}>
              &nbsp;Sign In
            </Link>
          </Typography>
        </Box>

        <Box style={{ marginTop: SPACING.s_32 }}>
          <Captcha form={form} />
        </Box>

        <Box
          display="flex"
          justifyContent="flex-end"
          style={{ marginTop: SPACING.s_60 }}>
          <Button
            type="submit"
            typeClassName="primary"
            isSpinner={requestState.isLoading}>
            Send Password Reset Link
          </Button>
        </Box>
      </Box>
    </Authorization>
  );
};

export default PageForgotPassword;

interface Props {}

type ClassNames =
  | "root"
  | "section"
  | "sectionTitle"
  | "sectionContent"
  | "sectionAboutContent";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {},
  section: {},
  sectionTitle: {
    marginBottom: SPACING.s_40,
  },
  sectionContent: {
    display: "flex",
    flexDirection: "column",

    "& > *": {
      marginTop: `${SPACING.s_16}px !important`,
    },

    "& > :first-of-type": {
      marginTop: `0 !important`,
    },
  },

  sectionAboutContent: {
    display: "flex",
    flexDirection: "column",

    "& > *": {
      marginTop: `${SPACING.s_60}px`,
    },
  },
}));
