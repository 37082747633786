import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { StateAuth } from "../middleware/api/auth/stateEntity/reducer";
import { AppState } from "../store/rootReducer";

export function useIsAuthorized() {
  const { user } = useSelector<AppState, StateAuth>((state) => state.auth);
  const [isAuthorized, setIsAuthorized] = useState(!!user);

  useEffect(() => {
    setIsAuthorized(!!user);
  }, [user]);

  return isAuthorized;
}
