import { Box, Theme } from "@mui/material";
import clsx from "clsx";
import React, { ComponentProps, FC } from "react";
import { makeStyles } from "tss-react/mui";

import SvgIcon from "../SvgIcon";
import Typography from "../Typography";

const Empty: FC<Props> = ({ isCenterFlex, isCenterAbsolute, ...rest }) => {
  const { classes } = useStyles();
  return (
    <Box
      className={clsx(classes.root, {
        [classes.centerFlex]: isCenterFlex,
        [classes.centerAbsolute]: isCenterAbsolute,
      })}>
      <Box className={classes.content}>
        <SvgIcon
          name={"table"}
          style={{ color: "#C7C7C7", width: 32, height: 32 }}
        />
        <Typography variant={"overline"} style={{ marginTop: 8 }}>
          No Data yet
        </Typography>
      </Box>
    </Box>
  );
};

export default Empty;

interface Props extends ComponentProps<any> {
  isCenterAbsolute?: boolean;
  isCenterFlex?: boolean;
}

type ClassNames = "root" | "content" | "centerAbsolute" | "centerFlex";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
  },
  content: {
    width: "fit-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  centerAbsolute: {
    position: "absolute",
    top: "50%",
    right: "50%",
    transform: "translate(50%, -50%)",
  },
  centerFlex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
