const INTERCOM_ID = process.env.REACT_APP_KEY_INTERCOM || "vi2kuzop";

const pingIntercom = async (intercomArr: any) => {
  try {
    const response = await fetch(
      "https://api-iam.intercom.io/messenger/web/ping",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...{
            app_id: INTERCOM_ID,
            v: "3",
            referer: window.location.href,
            api_base: "https://api-iam.intercom.io",
            page_title: "Family Office",
          },
          ...intercomArr,
        }),
      },
    );
    return response.json();
  } catch (error) {
    console.error("Error pinging Intercom:", error);
  }
};

export const utilsIntercom = { pingIntercom };
