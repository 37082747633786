import { useForm as reactHookFormUseForm, UseFormProps } from "react-hook-form";

const useForm = <TFormValues extends Record<string, any>>(
  config: UseFormProps<TFormValues> = {},
) => {
  const defaultConfig: UseFormProps<TFormValues> = {
    mode: "all",
  };

  const mergedConfig: UseFormProps<TFormValues> = {
    ...defaultConfig,
    ...config,
  };

  return reactHookFormUseForm<TFormValues>(mergedConfig);
};

export default useForm;
