import { Box, Theme } from "@mui/material";
import { ThunkDispatch } from "@reduxjs/toolkit";
import * as React from "react";
import { FC, useContext, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { useApiRequest } from "../../../hooks/useApiRequest";
import {
  AuthAction,
  LOGIN_SUCCESS,
} from "../../../middleware/api/auth/stateEntity/actions";
import { StateAuth } from "../../../middleware/api/auth/stateEntity/reducer";
import { API_ENDPOINTS } from "../../../middleware/api/endpoints.api";
import { AppState } from "../../../store/rootReducer";
import { RootState } from "../../../store/store";
import { SPACING } from "../../../theme/spacing";
import { AlertContext } from "../../atoms/Alert/AlertContextProvider";
import Typography from "../../atoms/Typography";
import Authorization from "../../templates/Authorization";
import { PathPage } from "../pathPage";

const PageConfirmEmail: FC<Props> = (props: Props) => {
  const { classes } = useStyles();

  const [searchParams] = useSearchParams();
  const hash = searchParams.get("hash");
  const navigate = useNavigate();

  const { showAlert } = useContext(AlertContext);

  const [requestState, sendRequest] = useApiRequest<any, any>(
    API_ENDPOINTS.AUTH.EMAIL_VERIFICATION_CONFIRM,
    "POST",
  );

  const requestSentRef = useRef(false);

  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AuthAction>>();
  const { user } = useSelector<AppState, StateAuth>((state) => state.auth);

  useEffect(() => {
    if (!hash) return;
    if (requestSentRef.current) return;
    requestSentRef.current = true;
    sendRequest({ hash })
      .then(({ email }) => {
        const pathPage = user ? PathPage.Settings : "/";
        if (user) {
          dispatch({ type: LOGIN_SUCCESS, payload: { ...user, email } });
        }
        showAlert("Email was confirmed!", "success");

        setTimeout(() => {
          navigate(pathPage);
        }, 3000);
      })
      .catch((error) => {
        showAlert("Error verification email, please contact us!", "error");
        console.error("Error fetching requests:", error);
      });
  }, [dispatch, hash, navigate, sendRequest, showAlert, user]);

  useEffect(() => {
    if (!hash) {
      navigate("/");
    }
  }, [hash, navigate]);

  return (
    <Authorization>
      <Box>
        <Box className={classes.sectionTitle}>
          <Typography variant="h1">Email Confirmation</Typography>
          <Typography
            variant="body1"
            style={{ marginTop: 10, display: "flex" }}>
            Wait a moment while we confirm your new email
          </Typography>
        </Box>
      </Box>
    </Authorization>
  );
};

export default PageConfirmEmail;

interface Props {}

type ClassNames =
  | "root"
  | "section"
  | "sectionTitle"
  | "sectionContent"
  | "sectionAboutContent";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {},
  section: {},
  sectionTitle: {
    marginBottom: SPACING.s_40,
  },
  sectionContent: {
    display: "flex",
    flexDirection: "column",

    "& > *": {
      marginTop: `${SPACING.s_16}px !important`,
    },

    "& > :first-of-type": {
      marginTop: `0 !important`,
    },
  },

  sectionAboutContent: {
    display: "flex",
    flexDirection: "column",

    "& > *": {
      marginTop: `${SPACING.s_60}px`,
    },
  },
}));
