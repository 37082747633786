import { Theme, Tooltip as MUITooltip, TooltipProps } from "@mui/material";
import clsx from "clsx";
import React, { FC, forwardRef } from "react";
import { makeStyles } from "tss-react/mui";

import { COLORS } from "../../../theme/colors";
import IconButton from "../IconButton";
import SvgIcon from "../SvgIcon";
import { DomainIconName } from "../SvgIcon/type";

const Tooltip: FC<Props> = forwardRef(
  ({ children, iconName, className, classes: propsClasses, ...rest }, ref) => {
    const { classes } = useStyles();

    if (!!iconName) {
      return (
        <MUITooltip
          placement="bottom"
          classes={{ popper: clsx(classes.popper, propsClasses?.popper) }}
          enterDelay={300}
          {...rest}
          className={clsx(classes.root, className)}
          ref={ref}>
          <span>
            {/*style={{ marginLeft: 4, marginTop: -8 }}*/}
            <IconButton>
              <SvgIcon name={iconName} width={18} height={18} />
            </IconButton>
          </span>
        </MUITooltip>
      );
    }

    return (
      <MUITooltip
        placement="bottom"
        classes={{ popper: clsx(classes.popper, propsClasses?.popper) }}
        enterDelay={300}
        {...rest}
        className={clsx(classes.root, className)}
        ref={ref}>
        <span>{children}</span>
      </MUITooltip>
    );
  },
);

Tooltip.displayName = "Tooltip";

export default Tooltip;

interface Props extends Omit<TooltipProps, "children"> {
  iconName?: DomainIconName;
  children?: any;
  classes?: { root?: any; popper?: any };
}

type ClassNames = "root" | "popper";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    "& .MuiSvgIcon-root": {
      color: COLORS.primary.grey50,
    },
    "&:hover": {
      "& .MuiSvgIcon-root": {
        color: COLORS.primary.dark,
      },
    },
  },
  popper: {
    textAlign: "center",

    "& .MuiTooltip-tooltip": {
      maxWidth: 500,
      ...(theme.typography.overline as any),
      padding: "8px 16px",
      color: COLORS.primary.white,
    },
  },
}));
