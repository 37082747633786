import {
  IconButton as MUIIconButton,
  IconButtonProps,
  SvgIconProps,
  Theme,
} from "@mui/material";
import clsx from "clsx";
import React, { FC, forwardRef } from "react";
import { makeStyles } from "tss-react/mui";

import { COLORS } from "../../../theme/colors";
import SvgIcon from "../SvgIcon";
import { DOMAIN_ICONS } from "../SvgIcon/type";

const IconButton: FC<Props> = forwardRef(
  (
    {
      children,
      name = "warning",
      typeClassName = "default",
      className,
      propsSvgIcon,
      ...rest
    },
    ref,
  ) => {
    const { classes } = useStyles();

    return (
      <MUIIconButton
        disableRipple
        {...rest}
        className={clsx(classes.root, classes[typeClassName], className)}
        ref={ref}>
        {children || <SvgIcon {...propsSvgIcon} name={name} />}
      </MUIIconButton>
    );
  },
);

IconButton.displayName = "IconButton";
export default IconButton;

interface Props extends IconButtonProps {
  typeClassName?: ClassNames;
  name?: keyof typeof DOMAIN_ICONS;
  propsSvgIcon?: SvgIconProps;
}

type ClassNames = "root" | "circle" | "default";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  default: {
    width: 24,
    height: 24,
    color: COLORS.primary.dark,
    "&:hover": {
      cursor: "pointer",
    },
  },
  root: {
    padding: 0,
    marginRight: 0,
  },
  circle: {
    width: "40px",
    height: "40px",

    borderRadius: "99px",
    background: COLORS.primary.white,
    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.15)",

    "&:hover": {
      background: COLORS.primary.grey10,
    },
    "&:active": {
      background: COLORS.primary.grey10,
    },
    "&.Mui-disabled": {
      background: COLORS.primary.grey20,
    },

    "& > svg": {
      width: 24,
      height: 24,
      color: COLORS.primary.dark,
    },
  },
}));
