import {
  Box,
  CircularProgress as MUICircularProgress,
  CircularProgressProps,
  Theme,
} from "@mui/material";
import clsx from "clsx";
import React, { FC, memo } from "react";
import { makeStyles } from "tss-react/mui";

const CircularProgress: FC<Props> = memo(
  ({ isCenterAbsolute, styleWrapper, isCenterFlex, isTable, ...rest }) => {
    const { classes } = useStyles();

    return (
      <Box
        style={{ ...styleWrapper }}
        className={clsx({
          [classes.table]: isTable,
          [classes.centerFlex]: isCenterFlex,
        })}>
        <MUICircularProgress
          disableShrink
          {...rest}
          className={clsx({
            [classes.centerAbsolute]: isCenterAbsolute,
          })}
        />
      </Box>
    );
  },
);

CircularProgress.displayName = "CircularProgress";

export default CircularProgress;

interface Props extends CircularProgressProps {
  isCenterAbsolute?: boolean;
  isCenterFlex?: boolean;
  isTable?: boolean;
  styleWrapper?: any;
}

type ClassNames = "root" | "centerAbsolute" | "centerFlex" | "table";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {},
  centerAbsolute: {
    position: "absolute",
    top: "50%",
    right: "50%",
    transform: "translate(50%, -50%)",
  },
  centerFlex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    height: "270px",
  },
}));
