import { AuthAction, login, me } from "./actions";
import reducer, { StateAuth } from "./reducer";

interface StateEntityAuth {
  reducer: (state: StateAuth | undefined, action: AuthAction) => StateAuth;
  actions: { login: any; me: any };
}

export const stateEntityAuth: StateEntityAuth = {
  reducer,
  actions: { login, me },
};
