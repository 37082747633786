import { Box, FormGroup } from "@mui/material";
import * as React from "react";
import { ComponentProps, FC, useMemo } from "react";
import { useController } from "react-hook-form";
import { UseFormReturn } from "react-hook-form/dist/types/form";

import Checkbox from "../../../atoms/@form/Checkbox";
import FormControlLabel from "../../../atoms/@form/FormControlLabel";
import FormHelperText from "../../../atoms/@form/FormHelperText";
import TextField from "../../../atoms/@form/TextField";
import Typography from "../../../atoms/Typography";
import { useStyles } from "../styles";

const SectionGoals: FC<Props> = ({
  form: {
    formState: { errors },
    control,
  },
}: Props) => {
  const { classes } = useStyles();
  const labels = useMemo(
    () => [
      "Marketing for a client",
      "Promoting a service",
      "Raising capital",
      "Job searching",
      "Selling A Service",
      "Deal sourcing",
      "Seeking a family office partner",
      "Other",
    ],
    [],
  );

  const {
    field: goalsField,
    fieldState: { error },
  } = useController({
    name: "goals",
    control,
  });

  const { field: goalsOtherField } = useController({
    name: "goalsOther",
    control,
  });

  const handleCheckboxChange = (item: string, checked: boolean) => {
    const value = checked
      ? [...goalsField.value, item]
      : goalsField.value.filter((f: any) => f !== item);

    goalsField.onChange(value);
  };

  const handleOtherTextChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    goalsOtherField.onChange(event.target.value);
  };

  return (
    <Box className={classes.section}>
      <Typography variant="h2" className={classes.sectionTitle}>
        2.What are your goals with using the platform? (Please check all that
        apply) *
      </Typography>
      <FormGroup className={classes.sectionContent}>
        {labels.map((label) => (
          <FormControlLabel
            key={label}
            control={
              <Checkbox
                checked={goalsField.value.includes(label)}
                onChange={(e) => handleCheckboxChange(label, e.target.checked)}
              />
            }
            disableTypography={label === "Other"}
            label={
              label === "Other" ? (
                <TextField
                  {...goalsOtherField}
                  onChange={handleOtherTextChange}
                  inputRef={goalsOtherField.ref}
                  onBlur={goalsOtherField.onBlur}
                  placeholder={label}
                  fullWidth
                  disabled={!goalsField.value.includes(label)}
                />
              ) : (
                label
              )
            }
          />
        ))}
      </FormGroup>

      {error && <FormHelperText>{error.message}</FormHelperText>}
    </Box>
  );
};

interface Props extends ComponentProps<any> {
  form: UseFormReturn<any>;
}

export default SectionGoals;
