import { Theme } from "@mui/material";
import Cookies from "js-cookie";
import * as React from "react";
import { FC, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { PathPage } from "../pathPage";

const CookieExpirationTime = 1 / 48; // 30 minutes in days

const PageCartMiddleware: FC<Props> = () => {
  const { packageId, period } = useParams();

  useEffect(() => {
    Cookies.set("CartMiddleware", JSON.stringify({ packageId, period }), {
      expires: CookieExpirationTime,
    });
  }, [packageId, period]);

  return (
    <Navigate to={PathPage.SignUp} state={{ packageId, period }} replace />
  );
};

export default PageCartMiddleware;

interface Props {}

type ClassNames = "root";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {},
}));
