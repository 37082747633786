import { array, object, string } from "yup";

import { FORM_VALIDATION_RULES } from "../../../atoms/@form/validationRules";

export const validationSchema = object().shape({
  specific: object().shape({
    firmName: string().test(
      "either-firm-name-or-contact",
      "Either Office Name or Contact is required",
      function (value) {
        return (
          (value && value.trim() !== "") ||
          (this.parent.firmContactPerson &&
            this.parent.firmContactPerson.trim() !== "")
        );
      },
    ),
    website: FORM_VALIDATION_RULES.website,
    country: string(),
    city: string(),
    firmContactPerson: string().test(
      "either-firm-name-or-contact",
      "Either Office Name or Contact is required",
      function (value) {
        return (
          (value && value.trim() !== "") ||
          (this.parent.firmName && this.parent.firmName.trim() !== "")
        );
      },
    ),
    comment: string(),
    type: string(),
  }),
  general: object()
    .shape({
      familyOfficeType: array(),
      investmentSize: array(),
      dealType: array(),
      industry: array(),
      country: string(),
      city: string(),
      targetGeography: array(),
      comment: string(),
      type: string(),
    })
    .test(
      "at-least-one-field",
      "At least one field must have a value",
      function (value) {
        const fieldsToCheck = Object.entries(value).filter(
          ([key]) => key !== "type",
        );
        return fieldsToCheck.some(
          ([_, fieldValue]) =>
            (Array.isArray(fieldValue) && fieldValue.length > 0) ||
            (typeof fieldValue === "string" && fieldValue.trim() !== ""),
        );
      },
    ),
});
