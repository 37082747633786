import { ThunkDispatch } from "@reduxjs/toolkit";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppState } from "../../../../store/rootReducer";
import { RootState } from "../../../../store/store";
import { StateAuth } from "../../auth/stateEntity/reducer";
import { fetchSearch } from "./search.thunk";

export const useFetchSearch = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, any>>();
  const search = useSelector((state: RootState) => state.search);
  const { user } = useSelector<AppState, StateAuth>((state) => state.auth);
  const hasFetchedRef = useRef(false);

  useEffect(() => {
    if (
      user &&
      (search.status === "idle" || search.status === "failed") &&
      !hasFetchedRef.current
    ) {
      hasFetchedRef.current = true;
      dispatch(fetchSearch());
    }
  }, [dispatch, search.status, user]);

  return search;
};
