import { createAsyncThunk } from "@reduxjs/toolkit";

import { http } from "../../../http";
import { API_ENDPOINTS } from "../../endpoints.api";
import { FirmsStats } from "../../stats/types";

export const fetchStatistics = createAsyncThunk(
  "offices/fetchStatistics",
  async () => {
    const response = await http.get<FirmsStats>(
      API_ENDPOINTS.OFFICES.STATISTICS,
    );
    return response.data;
  },
);
