import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Theme } from "@mui/material";
import * as React from "react";
import { FC, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { useApiRequest } from "../../../hooks/useApiRequest";
import { API_ENDPOINTS } from "../../../middleware/api/endpoints.api";
import { SPACING } from "../../../theme/spacing";
import useForm from "../../atoms/@form/useForm";
import { AlertContext } from "../../atoms/Alert/AlertContextProvider";
import Button from "../../atoms/Button";
import Typography from "../../atoms/Typography";
import TextFieldPassword from "../../molecules/TextFieldPassword";
import Authorization from "../../templates/Authorization";
import { validationSchema } from "./form";

const PageResetPassword: FC<Props> = (props: Props) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const hash = searchParams.get("hash");

  useEffect(() => {
    if (!hash) {
      window.location.href = "/login";
    } else {
    }
  }, [hash]);

  const [requestState, sendRequest] = useApiRequest<any, any>(
    API_ENDPOINTS.AUTH.RESET_PASSWORD,
    "POST",
  );

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({
    defaultValues: {
      password: "",
      passwordConfirm: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const { showAlert } = useContext(AlertContext);
  const onSubmit = (data: any) => {
    const { email, password } = data;

    sendRequest({ password, hash })
      .then(() => {
        showAlert("Your password has been changed!", "success");
        window.location.href = "/login";
      })
      .catch((error) => {
        console.error("Error fetching requests:", error);
      });
  };

  return (
    <Authorization>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Box className={classes.sectionTitle}>
          <Typography variant="h1">Reset Password</Typography>
        </Box>
        <Box className={classes.sectionAboutContent}>
          <TextFieldPassword
            style={{ marginTop: 0 }}
            label="Password"
            {...register("password")}
            error={!!errors.password}
            helperText={errors.password?.message}
          />
          <TextFieldPassword
            style={{ marginTop: 40 }}
            label="Confirm Password"
            {...register("passwordConfirm")}
            error={!!errors.passwordConfirm}
            helperText={errors.passwordConfirm?.message}
          />
        </Box>

        <Box
          display="flex"
          justifyContent="flex-end"
          style={{ marginTop: SPACING.s_60 }}>
          <Button
            typeClassName="primary"
            type="submit"
            isSpinner={requestState.isLoading}>
            Reset Password
          </Button>
        </Box>
      </Box>
    </Authorization>
  );
};

export default PageResetPassword;

interface Props {}

type ClassNames =
  | "root"
  | "section"
  | "sectionTitle"
  | "sectionContent"
  | "sectionAboutContent";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {},
  section: {},
  sectionTitle: {
    marginBottom: SPACING.s_40,
  },
  sectionContent: {
    display: "flex",
    flexDirection: "column",

    "& > *": {
      marginTop: `${SPACING.s_16}px !important`,
    },

    "& > :first-of-type": {
      marginTop: `0 !important`,
    },
  },

  sectionAboutContent: {
    display: "flex",
    flexDirection: "column",

    "& > *": {
      marginTop: `${SPACING.s_60}px`,
    },
  },
}));
