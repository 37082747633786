const BUILD_TIMESTAMP =
  process.env.REACT_APP_BUILD_TIMESTAMP || Date.now().toString();

export function register() {
  if (
    process.env.REACT_APP_ENV === "production" &&
    "serviceWorker" in navigator
  ) {
    window.addEventListener("load", () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js?t=${BUILD_TIMESTAMP}`;
      navigator.serviceWorker
        .register(swUrl)
        .then((registration) => {
          console.warn("Service worker registered:", registration, `${swUrl}`);
          registration.onupdatefound = () => {
            const installingWorker = registration.installing;
            if (installingWorker == null) {
              return;
            }
            installingWorker.onstatechange = () => {
              if (installingWorker.state === "installed") {
                if (navigator.serviceWorker.controller) {
                  console.warn("New content is available; please refresh");
                  window.location.reload();
                } else {
                  console.warn("Content is cached for offline use");
                }
              }
            };
          };
        })
        .catch((error) => {
          console.error("Error during service worker registration:", error);
        });
    });
  }
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}
