import Cookies from "js-cookie";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { PathPage } from "../../pathPage";

export const usePackageAndPeriod = () => {
  const location = useLocation();

  return useMemo(() => {
    if (location.pathname === PathPage.Subscriptions) {
      Cookies.remove("CartMiddleware");
      if (location.state?.packageId && location.state?.period) {
        return {
          packageId: location.state.packageId,
          period: location.state.period,
        };
      }
    } else {
      const cartMiddleware = Cookies.get("CartMiddleware");
      if (cartMiddleware) {
        try {
          const { packageId, period } = JSON.parse(cartMiddleware);
          if (packageId && period) {
            return { packageId, period };
          }
        } catch (error) {
          console.error("Error parsing CartMiddleware cookie:", error);
        }
      }
    }

    return {};
  }, [location.pathname, location.state?.packageId, location.state?.period]);
};
