import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RoleEnum } from "../middleware/@types/user.type";
import { StateAuth } from "../middleware/api/auth/stateEntity/reducer";
import { AppState } from "../store/rootReducer";

export function useIsAdmin() {
  const { user } = useSelector<AppState, StateAuth>((state) => state.auth);
  const [role, setIsRole] = useState(user?.role === RoleEnum.admin);

  useEffect(() => {
    setIsRole(user?.role === RoleEnum.admin);
  }, [user]);

  return role;
}
