import { createAsyncThunk } from "@reduxjs/toolkit";

import { http } from "../../../http";
import { API_ENDPOINTS } from "../../endpoints.api";
import { FiltersAll } from "../types";

export const fetchFilters = createAsyncThunk(
  "offices/fetchFilters",
  async () => {
    const response = await http.post<FiltersAll>(
      API_ENDPOINTS.OFFICES.FILTERS_TYPES,
      {},
    );
    return response.data;
  },
);
