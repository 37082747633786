import { Menu as MUIMenu, MenuProps, Theme } from "@mui/material";
import clsx from "clsx";
import React, { FC } from "react";
import { makeStyles } from "tss-react/mui";

const Menu: FC<Props> = ({
  children,
  classes: propsClasses,
  className,
  ...rest
}) => {
  const { classes } = useStyles();
  return (
    <MUIMenu
      classes={{ root: propsClasses?.root, paper: propsClasses?.paper }}
      className={clsx(classes.root, className)}
      {...rest}>
      {children}
    </MUIMenu>
  );
};

export default Menu;

interface Props extends MenuProps {
  classes?: { root?: any; paper?: any };
}

type ClassNames = "root";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {},
}));
