import { Checkbox as MUICheckbox, CheckboxProps, Theme } from "@mui/material";
import React, { FC, forwardRef } from "react";
import { makeStyles } from "tss-react/mui";

import { COLORS } from "../../../../theme/colors";
import SvgIcon from "../../SvgIcon";

const Checkbox: FC<Props> = forwardRef(({ ...rest }, ref) => {
  const { classes } = useStyles();
  return (
    <MUICheckbox
      disableRipple
      icon={<></>}
      checkedIcon={
        <div className={classes.checked}>
          <SvgIcon name="check" width={16} height={16} />
        </div>
      }
      classes={{ root: classes.root }}
      {...rest}
      ref={ref}
    />
  );
});

Checkbox.displayName = "Checkbox";

export default Checkbox;
interface Props extends CheckboxProps {}

type ClassNames = "root" | "checked";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    padding: 0,
    marginRight: 14,

    minWidth: 20,
    minHeight: 20,

    width: 20,
    height: 20,
    border: `1px solid ${COLORS.primary.grey40}`,
    borderRadius: 3,

    "&:hover": {
      backgroundColor: "unset",
      border: `1px solid ${COLORS.primary.green}`,
    },

    "&.Mui-disabled": {
      border: `1px solid ${COLORS.primary.grey40}`,
      backgroundColor: COLORS.primary.grey20,
    },

    "&.Mui-checked": {
      border: `1px solid ${COLORS.primary.green}`,
      color: COLORS.primary.white,
      backgroundColor: COLORS.primary.green,
    },

    "&.MuiCheckbox-indeterminate": {
      border: "none",
    },

    // "&.MuiFormControlLabel-root": {
    //   marginLeft: "-10px",
    // },
  },
  checked: {
    display: "flex",

    "& .MuiSvgIcon-root": {
      color: COLORS.primary.white,
      width: 16,
      height: 16,
    },
  },
}));
