import {
  DialogContent as MUIDialogContent,
  DialogContentProps,
  Theme,
} from "@mui/material";
import clsx from "clsx";
import * as React from "react";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";

import { SPACING } from "../../../theme/spacing";

const DialogContent: FC<Props> = ({ children, className, ...rest }: Props) => {
  const { classes } = useStyles();

  return (
    <MUIDialogContent className={clsx(classes.root, className)} {...rest}>
      {children}
    </MUIDialogContent>
  );
};

export default DialogContent;

interface Props extends DialogContentProps {}

type ClassNames = "root";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: { padding: `0 ${SPACING.s_48}px` },
}));
