import {
  IconButton,
  InputAdornment,
  StandardTextFieldProps,
  TextField as MUITextField,
  Theme,
} from "@mui/material";
import clsx from "clsx";
import React, { FC, forwardRef, useState } from "react";
import { makeStyles } from "tss-react/mui";

import { ReactComponent as IconEye } from "../../../assets/icons/eye.svg";
import { ReactComponent as IconEyeSlash } from "../../../assets/icons/eye-slash.svg";
import { COLORS } from "../../../theme/colors";

const TextFieldPassword: FC<Props> = forwardRef(
  ({ className, ...rest }, ref) => {
    const { classes } = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
      setShowPassword((show) => !show);
    };

    const handleMouseDownPassword = (
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
      event.preventDefault();
    };
    return (
      <MUITextField
        label="Label text"
        variant="standard"
        type={showPassword ? "text" : "password"}
        ref={ref}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end">
                {showPassword ? (
                  <IconEye
                    width={18}
                    height={18}
                    style={{ color: COLORS.primary.grey50 }}
                  />
                ) : (
                  <IconEyeSlash
                    width={18}
                    height={18}
                    style={{ color: COLORS.primary.grey50 }}
                  />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
        rows={4}
        className={clsx(classes.root, className)}
        {...rest}
      />
    );
  },
);

TextFieldPassword.displayName = "TextFieldPassword";

export default TextFieldPassword;

interface Props extends StandardTextFieldProps {}

type ClassNames = "root";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    "& .MuiFormLabel-root": {
      marginTop: -16,
    },
    "& .MuiFormLabel-root+.MuiInputBase-root": {
      marginTop: 0,
    },

    // "& .MuiInputBase-multiline": {
    //   minHeight: 84,
    // },

    "& .MuiFormLabel-root.MuiInputLabel-root": {
      color: COLORS.primary.grey50,
    },
    "& .MuiInputBase-root:after": {
      borderWidth: 1,
      borderColor: COLORS.primary.green,
    },
    "& .MuiInputBase-root:before": {
      borderBottomWidth: 1,
      borderColor: COLORS.primary.grey30,
      borderBottomStyle: "solid",
    },
    "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error)::before": {
      borderBottomWidth: 1,
      borderColor: COLORS.primary.green,
      borderBottomStyle: "solid",
    },

    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: COLORS.primary.green,
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: 1,
      borderColor: COLORS.primary.green,
      borderBottomStyle: "solid",
    },

    "& .MuiFormLabel-asterisk": {
      color: COLORS.secondary.red,
    },
    "& .MuiInputBase-inputMultiline": {
      resize: "auto" as any,
    },
  },
}));
