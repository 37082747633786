import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { FiltersAll } from "../types";
import { fetchFilters } from "./filters.thunk";

interface FiltersState {
  data: FiltersAll | null;
  status: "idle" | "loading" | "succeeded" | "failed";
  isLoading: boolean;
  error: string | null;
}

const initialState: FiltersState = {
  data: null,
  status: "idle",
  isLoading: false,
  error: null,
};

export const sliceFilters = createSlice({
  name: "filters",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFilters.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(
        fetchFilters.fulfilled,
        (state, action: PayloadAction<FiltersAll>) => {
          state.status = "succeeded";
          state.isLoading = false;
          state.data = action.payload;
        },
      )
      .addCase(fetchFilters.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});
