import { Theme } from "@mui/material";
import usePagination, { UsePaginationProps } from "@mui/material/usePagination";
import { UsePaginationItem } from "@mui/material/usePagination/usePagination";
import clsx from "clsx";
import React, { FC } from "react";
import { makeStyles } from "tss-react/mui";

import { COLORS } from "../../../theme/colors";

const UsePagination: FC<Props> = ({
  count,
  page,
  onChange,
  isFrontendPagination = true,
  totalItems,
  itemsPerPage = 10,
  ...rest
}) => {
  const { classes } = useStyles();

  const paginationProps = isFrontendPagination
    ? { count, page, onChange }
    : {
        count: Math.ceil((totalItems || 0) / itemsPerPage),
        page,
        onChange,
      };

  const { items } = usePagination({
    ...paginationProps,
    siblingCount: 0,
  });

  return (
    <nav>
      <ul className={classes.ul}>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;
          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = (
              <Number {...item} disabled>
                …
              </Number>
            );
          } else if (type === "page") {
            children = (
              <Number selected={selected} {...item}>
                {page}
              </Number>
            );
          } else {
            children = <PrevNext type={type} {...item} />;
          }
          return <li key={index}>{children}</li>;
        })}
      </ul>
    </nav>
  );
};

const Number = ({ children, selected, ...rest }: any) => {
  const { classes } = useStyles();
  return (
    <button
      type="button"
      className={clsx(classes.button, selected ? classes.buttonSelected : {})}
      {...rest}>
      {children}
    </button>
  );
};

const PrevNext = ({ type, ...rest }: Partial<UsePaginationItem>) => {
  const { classes } = useStyles();
  return (
    <button
      type="button"
      className={clsx(
        type === "previous" ? classes.buttonPrev : classes.buttonNext,
        classes.button,
        classes.buttonPrevNext,
      )}
      {...rest}>
      {type}
    </button>
  );
};

export default UsePagination;

interface Props extends UsePaginationProps {
  isFrontendPagination?: boolean;
  totalItems?: number;
  itemsPerPage?: number;
}

type ClassNames =
  | "root"
  | "ul"
  | "button"
  | "buttonPrevNext"
  | "buttonNext"
  | "buttonPrev"
  | "buttonHover"
  | "buttonSelected";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {},
  ul: {
    listStyle: "none",
    padding: 0,
    margin: 0,
    display: "flex",
  },
  buttonHover: {},
  button: {
    ...(theme.typography.overline as any),
    display: "flex",
    height: "42px",
    width: "42px",
    padding: "8px 12px",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${COLORS.primary.grey30}`,
    borderLeft: "none",
    gap: "36px",
    backgroundColor: COLORS.primary.white,

    color: COLORS.primary.grey50,

    "&:hover": {
      cursor: "pointer",
      background: COLORS.primary.grey10,
    },

    "&:active": {
      cursor: "pointer",

      color: COLORS.primary.white,
      backgroundColor: COLORS.secondary.green,
      borderColor: COLORS.secondary.green,
    },
    "&:disabled": {
      pointerEvents: "none",
      cursor: "default",
      // backgroundColor: DOMAIN_COLORS.primary.grey20,
      // color: DOMAIN_COLORS.primary.grey40,
      // borderColor: DOMAIN_COLORS.primary.grey20,
    },
  },
  buttonPrevNext: {
    textTransform: "capitalize",
    padding: "8px 28px",
    width: "auto",
  },
  buttonNext: {
    borderRadius: "0px 4px 4px 0px",
    width: "auto",
  },
  buttonPrev: {
    borderRadius: "4px 0px 0px 4px",
    borderLeft: `1px solid ${COLORS.primary.grey30}`,
    width: "auto",
  },
  buttonSelected: {
    color: COLORS.primary.white,
    backgroundColor: COLORS.primary.green,
    borderColor: COLORS.primary.green,

    "&:hover": {
      color: COLORS.primary.white,
      backgroundColor: COLORS.primary.green,
      borderColor: COLORS.primary.green,
    },
  },
}));
