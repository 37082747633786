import React, { ComponentProps, createContext, FC } from "react";

import { useCombinedFilters } from "./hooks/filters/useCombinedFilters";
import { useHookTabs } from "./hooks/useHookTabs";
import { useHookFavorite } from "./tabs/components/FavoriteStar/useHookFavorite";

export enum TabEnum {
  ALL = 0,
  FAVORITE = 1,
  SAVED = 2,
}

interface Props extends ComponentProps<any> {}

interface Context {
  listAll: {
    countOffices: any;
    initialOffices: any;
    getSearch: any;
    rows: any;
    setRows: any;
    requestState: any;
    isLoading: any;
    officesIdsExport: number[];
    setOfficesIdsExport: any;
  };
  listFavorite: {
    countOffices: any;
    initialOffices: any;
    getSearch: any;
    rows: any;
    setRows: any;
    requestState: any;
    officesIdsExport: number[];
    setOfficesIdsExport: any;
  };
  listSavedSearch: {
    countOffices: any;
    initialOffices: any;
    getSearch: any;
    rows: any;
    setRows: any;
    requestState: any;
  };

  favorite: {
    favorites: any;
    setFavorites: any;
    createFavorites: any;
    deleteFavorites: any;
    updateFavorites: any;
    getFavorites: any;
  };
  filters: {
    filtersTypes: any;
    filtersTypesInitial: any;
    filtersTypesActive: any;
    setFiltersTypesActive: any;
    filtersTypesRequestState: any;
  };
  filtersRefine: {
    filtersTypesRefine: any;
    setFiltersTypesRefine: any;
    filtersTypesRefineActive: any;
    setFiltersTypesRefineActive: any;
    requestStateFiltersTypesRefine: any;
    getFiltersTypesRefine: any;
  };

  activeTab: any;
  setActiveTab: any;
}

export const PageMyDataContext = createContext<Context>({} as Context);

const PageMyDataContextProvider: FC<Props> = ({ children }) => {
  const { favorite } = useHookFavorite();
  const { activeTab, setActiveTab, listAll, listFavorite, listSavedSearch } =
    useHookTabs();

  const { filtersRefine, filters } = useCombinedFilters(listAll);

  return (
    <PageMyDataContext.Provider
      value={{
        listAll,
        listFavorite,
        listSavedSearch,
        favorite,
        filters,
        filtersRefine,

        activeTab,
        setActiveTab,
      }}>
      {children}
    </PageMyDataContext.Provider>
  );
};
export default PageMyDataContextProvider;
