import {
  Box,
  InputLabel,
  TextField as MUITextField,
  Theme,
} from "@mui/material";
import { InputProps as StandardInputProps } from "@mui/material/Input/Input";
import {
  BaseTextFieldProps,
  TextFieldVariants,
} from "@mui/material/TextField/TextField";
import clsx from "clsx";
import React, { FC, forwardRef } from "react";
import { makeStyles } from "tss-react/mui";

import { COLORS } from "../../../../theme/colors";
import Tooltip from "../../Tooltip";

const TextField: FC<Props> = forwardRef(
  ({ children, className, tooltip, label, ...rest }, ref) => {
    const { classes } = useStyles();

    const InputLabelWithTooltip = (props: any) => (
      <Box>
        <InputLabel {...props} style={{ zIndex: 999999 }}>
          {label}
          <Tooltip iconName={"circleInfo"} title={tooltip || ""} arrow />
        </InputLabel>
      </Box>
    );

    return (
      <MUITextField
        maxRows={4}
        minRows={4}
        className={clsx(classes.root, className)}
        variant="standard"
        type="text"
        label={label}
        InputProps={{
          style: { resize: "none" },
          endAdornment: null,
        }}
        InputLabelProps={{
          component: tooltip ? InputLabelWithTooltip : undefined,
        }}
        {...rest}
        autoComplete="none"
        ref={ref}>
        {children}
      </MUITextField>
    );
  },
);
TextField.displayName = "TextField";

export default TextField;

interface Props extends BaseTextFieldProps {
  onChange?: StandardInputProps["onChange"];
  variant?: TextFieldVariants;
  InputProps?: Partial<StandardInputProps>;
  tooltip?: boolean;
}

type ClassNames = "root";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    "& .MuiFormLabel-root": {
      marginTop: -16,
    },
    "& .MuiFormLabel-root+.MuiInputBase-root": {
      marginTop: 0,
    },

    // "& .MuiInputBase-multiline": {
    //   minHeight: 84,
    // },

    "& .MuiFormLabel-root.MuiInputLabel-root": {
      color: COLORS.primary.grey50,
    },
    "& .MuiInputBase-root:after": {
      borderWidth: 1,
      borderColor: COLORS.primary.green,
    },
    "& .MuiInputBase-root:before": {
      borderBottomWidth: 1,
      borderColor: COLORS.primary.grey30,
      borderBottomStyle: "solid",
    },
    "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error)::before": {
      borderBottomWidth: 1,
      borderColor: COLORS.primary.green,
      borderBottomStyle: "solid",
    },

    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: COLORS.primary.green,
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: 1,
      borderColor: COLORS.primary.green,
      borderBottomStyle: "solid",
    },

    "& .MuiFormLabel-asterisk": {
      color: COLORS.secondary.red,
    },
    "& .MuiInputBase-inputMultiline": {
      resize: "none" as any,
    },
  },
}));
