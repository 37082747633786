import { Box } from "@mui/material";
import * as React from "react";
import { ComponentProps, FC } from "react";

import { SPACING } from "../../../../theme/spacing";
import Typography from "../../../atoms/Typography";

const SectionHeader: FC<Props> = () => {
  return (
    <Box>
      <Typography variant="h1">
        Welcome to our Family Office List community!
      </Typography>
      <Box display="flex" marginTop={`${SPACING.s_16}px`} flexDirection="row">
        <Typography variant="overline">
          Please take a moment to fill out this Onboarding Form to help us
          better understand your needs and goals, allowing us to tailor our
          services accordingly.
        </Typography>
      </Box>
    </Box>
  );
};

interface Props extends ComponentProps<any> {}

export default SectionHeader;
