import { createAsyncThunk } from "@reduxjs/toolkit";

import { http } from "../../../http";
import { API_ENDPOINTS } from "../../endpoints.api";
import { SubscriptionsAll } from "../types";

export const fetchSubscriptions = createAsyncThunk(
  "subscriptions/fetchSubscriptions",
  async () => {
    const response = await http.get<SubscriptionsAll>(
      API_ENDPOINTS.BILLING.SUBSCRIPTIONS,
    );
    return response.data;
  },
);
