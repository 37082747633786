import { Box } from "@mui/material";
import * as React from "react";
import { FC, Suspense, useRef } from "react";

import BoxContainer from "../../atoms/BoxContainer";
import Button from "../../atoms/Button";
import PortalHeader from "../../atoms/PortalHeader";
import PortalHeaderTablet from "../../atoms/PortalHeaderTablet";
import SvgIcon from "../../atoms/SvgIcon";
import Typography from "../../atoms/Typography";
import DialogResearchRequest from "../../organisms/@dialog/DialogResearchRequest";
import { DialogRef } from "../PageSettings/components/MyProfile";
import PageMyDataContextProvider from "./context";
import { useStyles } from "./styles";

const Content = React.lazy(() => import("./Content"));
const PageMyData: FC<Props> = (props: Props) => {
  return (
    <PageMyDataContextProvider>
      <Header />
      <HeaderTablet />

      <Suspense fallback={<></>}>
        <BoxContainer style={{ width: "100%" }}>
          <Content />
        </BoxContainer>
      </Suspense>
    </PageMyDataContextProvider>
  );
};

const ButtonResearchRequest = () => {
  const { classes } = useStyles();
  const dialogRef = useRef<DialogRef>(null);

  const openDialog = () => {
    dialogRef.current?.handleOpen();
  };
  return (
    <>
      <Button
        type="button"
        className={classes.headerButton}
        onClick={openDialog}
        endIcon={<SvgIcon name="fileAdd" />}
        typeClassName="secondary">
        Research Request
      </Button>
      <DialogResearchRequest ref={dialogRef} handleDialogData={() => ({})} />
    </>
  );
};

const Header = () => {
  const { classes } = useStyles();

  return (
    <PortalHeader>
      <Box className={classes.header}>
        <Typography variant="h2">My Data</Typography>
        <Box sx={{ display: { xs: "none", lg: "block" } }}>
          <ButtonResearchRequest />
        </Box>
      </Box>
    </PortalHeader>
  );
};

const HeaderTablet = () => {
  const { classes } = useStyles();

  return (
    <PortalHeaderTablet>
      <Box
        sx={{ display: { xs: "flex", lg: "none" } }}
        style={{ width: "100%" }}>
        <ButtonResearchRequest />
      </Box>
    </PortalHeaderTablet>
  );
};

export default PageMyData;

interface Props {}
