import { useHookFiltersAll } from "./useHookFiltersAll";
import { useHookFiltersRefine } from "./useHookFiltersRefine";

export function useCombinedFilters(listAll: any) {
  const { filters } = useHookFiltersAll();
  const { filtersRefine } = useHookFiltersRefine();

  return {
    filtersRefine,
    filters,
  };
}
