import { useCallback, useEffect, useRef, useState } from "react";

import { useApiRequest } from "../../../../../../hooks/useApiRequest";
import { API_ENDPOINTS } from "../../../../../../middleware/api/endpoints.api";
import { FavoritesFiltersEntity } from "../../../../../../middleware/api/entities/FavoritesFiltersEntity";

export function useHookFavorite() {
  const [favorites, setFavorites] = useState<number[]>([]);

  const [requestState, sendRequest] = useApiRequest<number[], any>(
    API_ENDPOINTS.OFFICES.FAVORITES_IDS,
    "GET",
  );

  const [, postSend] = useApiRequest<FavoritesFiltersEntity, any>(
    API_ENDPOINTS.OFFICES.FAVORITES,
    "POST",
  );

  const [, patchSend] = useApiRequest<FavoritesFiltersEntity, any>(
    API_ENDPOINTS.OFFICES.FAVORITES_REMOVE,
    "PATCH",
  );

  const requestSentRef = useRef(false);

  const getRequests = useCallback(() => {
    sendRequest()
      .then((data) => {
        if (data) setFavorites(data);
      })
      .catch((error) => {
        console.error("Error fetching requests:", error);
      });
  }, [sendRequest]);

  useEffect(() => {
    if (requestSentRef.current) return;
    requestSentRef.current = true;
    getRequests();
  }, [getRequests, requestState, requestState.data, sendRequest]);

  const createFavorites = (officeId: number) => {
    setFavorites([...favorites, officeId]);

    let favoritesInner = favorites;
    if (favoritesInner.includes(officeId)) {
      favoritesInner = favoritesInner.filter(
        (favorite) => favorite !== officeId,
      );
    } else {
      favoritesInner.push(officeId);
    }

    postSend({ favorites: favoritesInner, officeId }).catch((error) => {
      console.error("Error fetching requests:", error);
    });
  };

  const deleteFavorites = (officeId: number) => {
    const favoritesInner = favorites.filter(
      (favorite) => favorite !== officeId,
    );
    setFavorites(favoritesInner);

    patchSend({ favorites: favoritesInner, officeId }).catch((error) => {
      console.error("Error fetching requests:", error);
    });
  };

  const updateFavorites = (isFavorite: boolean, officeId: number) => {
    if (isFavorite) {
      deleteFavorites(officeId);
    } else {
      createFavorites(officeId);
    }
  };

  return {
    favorite: {
      favorites,
      setFavorites,
      createFavorites,
      deleteFavorites,
      updateFavorites,
      getFavorites: getRequests,
    },
  };
}
