import { useCallback, useState } from "react";

import { useApiRequest } from "../../../../hooks/useApiRequest";
import { API_ENDPOINTS } from "../../../../middleware/api/endpoints.api";
import { FamilyOfficesEntity } from "../../../../middleware/api/entities/FamilyOfficesEntity";

export function useHookListFavorite() {
  const [countOffices, setCountOffices] = useState<number>(0);
  const [initialOffices, setInitialOffices] = useState<any>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [officesIdsExport, setOfficesIdsExport] = useState<any[]>([]);

  const [requestState, sendRequest] = useApiRequest<FamilyOfficesEntity[], any>(
    API_ENDPOINTS.OFFICES.FAVORITES,
    "GET",
  );

  const getRequests = useCallback((): Promise<FamilyOfficesEntity[]> => {
    return sendRequest()
      .then((data) => {
        if (data) {
          setRows(data);
          return Promise.resolve(data);
        }
        return Promise.resolve([]);
      })
      .catch((error) => {
        console.error("Error fetching requests:", error);
        return Promise.resolve([]);
      });
  }, [sendRequest]);

  return {
    listFavorite: {
      countOffices,
      setCountOffices,
      initialOffices,
      setInitialOffices,
      getSearch: getRequests,
      requestState,
      rows,
      setRows,
      officesIdsExport,
      setOfficesIdsExport,
    },
  };
}
