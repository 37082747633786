import { boolean, object, string } from "yup";

import { FORM_VALIDATION_RULES } from "../../atoms/@form/validationRules";

export const validationSchema = object().shape({
  email: FORM_VALIDATION_RULES.email.required("Email is required"),
  password: string().required("Password is required"),
  isRememberMe: boolean(),
  recaptcha: string(),
});
