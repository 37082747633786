export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export interface PaginationDto {
  page?: number;

  limit?: number;
  orderBy?: string;
  orderDirection?: SortDirection;
}

export interface PaginatedResult<T> {
  items: T[];
  meta: {
    page: number;
    limit: number;
    total: number;
    totalPages: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
}
