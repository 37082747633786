import { Box } from "@mui/material";
import * as React from "react";
import { FC, Suspense, useCallback, useContext, useRef } from "react";

import { useApiRequest } from "../../../hooks/useApiRequest";
import { ResearchRequests } from "../../../middleware/api/customerRequests/types";
import { API_ENDPOINTS } from "../../../middleware/api/endpoints.api";
import BoxContainer from "../../atoms/BoxContainer";
import Button from "../../atoms/Button";
import PortalHeader from "../../atoms/PortalHeader";
import PortalHeaderTablet from "../../atoms/PortalHeaderTablet";
import SvgIcon from "../../atoms/SvgIcon";
import Typography from "../../atoms/Typography";
import DialogResearchRequest from "../../organisms/@dialog/DialogResearchRequest";
import { DialogRef } from "../PageSettings/components/MyProfile";
import PageMyRequestsContextProvider, {
  PageMyRequestsContext,
} from "./context";
import { useStyles } from "./styles";

const Content = React.lazy(() => import("./Content"));
const PageMyRequests: FC<Props> = (props: Props) => {
  return (
    <PageMyRequestsContextProvider>
      <Header />
      <HeaderTablet />

      <Suspense fallback={<></>}>
        <BoxContainer style={{ width: "100%" }}>
          <Content />
        </BoxContainer>
      </Suspense>
    </PageMyRequestsContextProvider>
  );
};

const ButtonResearchRequest = () => {
  const { setResearchResearchRequests } = useContext(PageMyRequestsContext);

  const [, sendRequest] = useApiRequest<ResearchRequests[], any>(
    API_ENDPOINTS.CUSTOMER_REQUESTS.RESEARCH_REQUESTS,
    "GET",
  );

  const getRequests = useCallback(() => {
    sendRequest()
      .then((data) => {
        if (data && data?.length > 0) setResearchResearchRequests(data);
      })
      .catch((error) => {
        console.error("Error fetching requests:", error);
      });
  }, [sendRequest, setResearchResearchRequests]);

  const { classes } = useStyles();
  const dialogRef = useRef<DialogRef>(null);

  const openDialog = () => {
    dialogRef.current?.handleOpen();
  };
  return (
    <>
      <Button
        type="button"
        className={classes.headerButton}
        onClick={openDialog}
        endIcon={<SvgIcon name="fileAdd" />}
        typeClassName="secondary">
        Research Request
      </Button>
      <DialogResearchRequest
        ref={dialogRef}
        handleDialogData={() => {
          getRequests();
        }}
      />
    </>
  );
};

const Header = () => {
  const { classes } = useStyles();

  return (
    <PortalHeader>
      <Box className={classes.header}>
        <Typography variant="h2">My Requests</Typography>
        <Box sx={{ display: { xs: "none", lg: "block" } }}>
          <ButtonResearchRequest />
        </Box>
      </Box>
    </PortalHeader>
  );
};

const HeaderTablet = () => {
  return (
    <PortalHeaderTablet>
      <Box
        sx={{ display: { xs: "flex", lg: "none" } }}
        style={{ width: "100%" }}>
        <ButtonResearchRequest />
      </Box>
    </PortalHeaderTablet>
  );
};

export default PageMyRequests;

interface Props {}
