import { AlertProps } from "@mui/material";
import React, { FC, useContext, useEffect, useRef } from "react";

import Alert from ".";
import { AlertContext } from "./AlertContextProvider";

const ALERT_LIFETIME = 5000;

const AlertController: FC<Props> = (props: Props) => {
  const { hideAlert } = useContext(AlertContext);
  const { children, severity, alertId, time, ...rest } = props;
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;

      setTimeout(
        () => {
          hideAlert(alertId);
        },
        time ? time : ALERT_LIFETIME,
      );
    }
  }, [time, isFirstRender, hideAlert, alertId]);

  return (
    <Alert {...rest} severity={severity}>
      {children}
    </Alert>
  );
};

export default AlertController;

interface Props extends AlertProps {
  alertId: number;
  time?: number;
}
