import React, { FC } from "react";
import { Helmet } from "react-helmet-async";

const MS_ID = "iyyeoea5n2";
const MsClarity: FC<any> = ({ children, ...rest }) => {
  return (
    <>
      <Helmet>
        <script>
          {`
          (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "${MS_ID}");
        `}
        </script>
      </Helmet>
    </>
  );
};

export default MsClarity;
