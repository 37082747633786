import { Box } from "@mui/material";
import * as React from "react";
import { ComponentProps, FC, useContext, useMemo } from "react";
import { Controller } from "react-hook-form";

import TextField from "../../../../../atoms/@form/TextField";
import CircularProgress from "../../../../../atoms/CircularProgress";
import Autocomplete from "../../../../../molecules/Autocomplete";
import { DialogResearchRequestContext } from "../../context";
import { useStyles } from "../../styles";
import { mapFirmsToOptions } from "../../utils";

const GeneralFamilyOfficeProfile: FC<Props> = (props: Props) => {
  const { classes } = useStyles();
  const {
    mainForm: {
      formState: { errors, isValid },
      register,
      setValue,
      getValues,
      control,
    },
    firmTypes,
  } = useContext(DialogResearchRequestContext);

  const transactionSize = useMemo(() => {
    return [
      { name: "0 - 1mm", value: { 0: 0, 1: 1 }, id: 1 },
      { name: "1mm - 5mm", value: { 0: 2, 1: 5 }, id: 2 },
      { name: "5mm - 10mm", value: { 0: 6, 1: 10 }, id: 3 },
      { name: "10mm - 25mm", value: { 0: 11, 1: 25 }, id: 4 },
      { name: "25mm - 50mm", value: { 0: 26, 1: 50 }, id: 5 },
      { name: "50mm - 100mm", value: { 0: 51, 1: 100 }, id: 6 },
      { name: "100mm - 500mm", value: { 0: 101, 1: 500 }, id: 7 },
      { name: "500mm - 1bn", value: { 0: 501, 1: 1000 }, id: 8 },
      { name: "1bn+", value: { 0: 1001, 1: 999999 }, id: 9 },
    ];
  }, []);

  if (!firmTypes)
    return (
      <Box className={classes.form} style={{ marginTop: 48 }}>
        <CircularProgress isCenterFlex />
      </Box>
    );

  return (
    <>
      <Box className={classes.form} style={{ marginTop: 48 }}>
        <Controller
          name="general.familyOfficeType"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              {...field}
              formControlField={field}
              options={mapFirmsToOptions(firmTypes.office)}
              propsTextField={{
                label: "Family Office Type",
                error: !!error,
                helperText: error?.message,
              }}
              fullWidth
            />
          )}
        />

        <Controller
          name="general.dealType"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              {...field}
              formControlField={field}
              options={mapFirmsToOptions(firmTypes.structures)}
              propsTextField={{
                label: "Deal Type",
                error: !!error,
                helperText: error?.message,
              }}
              fullWidth
            />
          )}
        />

        <Controller
          name="general.industry"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              {...field}
              formControlField={field}
              options={mapFirmsToOptions(firmTypes.subIndustries)}
              propsTextField={{
                label: "Industry",
                error: !!error,
                helperText: error?.message,
              }}
              fullWidth
            />
          )}
        />

        <Box display="flex">
          <TextField
            label="Country"
            fullWidth
            style={{ marginRight: 48 }}
            {...register("general.country")}
            error={!!errors.general?.country}
            helperText={errors.general?.country?.message}
          />
          <TextField
            label="City"
            fullWidth
            {...register("general.city")}
            error={!!errors.general?.city}
            helperText={errors.general?.city?.message}
          />
        </Box>

        <Controller
          name="general.targetGeography"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              {...field}
              formControlField={field}
              options={mapFirmsToOptions(firmTypes.continents)}
              propsTextField={{
                label: "Target Geography",
                error: !!error,
                helperText: error?.message,
              }}
              fullWidth
            />
          )}
        />

        <TextField
          fullWidth
          label="Notes"
          tooltip="Please share any additional details to assist our research team in adding the requested office and/or contact."
          maxRows={4}
          multiline
          {...register("general.comment")}
          error={!!errors.general?.comment}
          helperText={errors.general?.comment?.message}
        />
      </Box>
    </>
  );
};

interface Props extends ComponentProps<any> {}

export default GeneralFamilyOfficeProfile;
