import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { SubscriptionsAll } from "../types";
import { fetchSubscriptions } from "./subscriptions.thunk";

interface SubscriptionsState {
  data: SubscriptionsAll;
  status: "idle" | "loading" | "succeeded" | "failed";
  isLoading: boolean;
  error: string | null;
}

const initialState: SubscriptionsState = {
  data: {
    active: [],
    available: [],
    free: [],
    canDownloadExcel: false,
  },
  status: "idle",
  isLoading: false,
  error: null,
};

export const sliceSubscriptions = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscriptions.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(
        fetchSubscriptions.fulfilled,
        (state, action: PayloadAction<SubscriptionsAll>) => {
          state.status = "succeeded";
          state.isLoading = false;
          state.data = action.payload;
        },
      )
      .addCase(fetchSubscriptions.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});
