import { Box, DrawerProps, Theme } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { CSSObject, styled } from "@mui/material/styles";
import clsx from "clsx";
import * as React from "react";
import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { ReactComponent as SvgLogo } from "../../../assets/images/components/atoms/sidebar/logo.svg";
import { ReactComponent as SvgLogoWithText } from "../../../assets/images/components/atoms/sidebar/logo-with-text-white.svg";
import { COLORS } from "../../../theme/colors";
import { SPACING } from "../../../theme/spacing";
import SvgIcon from "../../atoms/SvgIcon";
import { PathPage } from "../../pages/pathPage";
import { NavigationListItem } from "./sidebar.type";

const drawerWidth = 216;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  // transition: theme.transitions.create("width", {
  //   easing: theme.transitions.easing.sharp,
  //   duration: theme.transitions.duration.enteringScreen,
  // }),
  overflow: "visible",
  marginRight: 40,
});

const closedMixin = (theme: Theme): CSSObject => ({
  // transition: theme.transitions.create("width", {
  //   easing: theme.transitions.easing.sharp,
  //   duration: theme.transitions.duration.leavingScreen,
  // }),
  width: 100,
  overflow: "visible",
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  ...theme.mixins.toolbar,
}));

const muiDrawerPaper = {
  backgroundColor: COLORS.primary.darkGreen,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  flex: "1 0 0",
};

const DrawerStyle = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      ...muiDrawerPaper,
      padding: "36px 0 0 40px",
      alignItems: "flex-start",
      overflow: "visible",
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      ...muiDrawerPaper,
      padding: "36px 0",
      alignItems: "center",
      overflow: "visible",
    },
  }),
}));

const Sidebar: FC<Props> = ({ navigationList, ...rest }) => {
  const { classes } = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const location = useLocation();

  const navigate = useNavigate();

  return (
    <Box style={{ position: "relative" }}>
      <DrawerStyle variant="permanent" open={open}>
        <DrawerHeader className={classes.drawerHeader}>
          <IconButton
            className={classes.buttonLogo}
            disableRipple
            onClick={() => navigate(PathPage.Overview)}>
            {open ? <SvgLogoWithText /> : <SvgLogo />}
          </IconButton>
          <IconButton
            disableRipple
            className={classes.buttonOpen}
            onClick={open ? handleDrawerClose : handleDrawerOpen}>
            {open ? (
              <SvgIcon name="chevronLeft" className={classes.buttonOpenSvg} />
            ) : (
              <SvgIcon name="chevronRight" className={classes.buttonOpenSvg} />
            )}
          </IconButton>
        </DrawerHeader>
        <List
          className={classes.list}
          style={open ? { marginLeft: -SPACING.s_16 } : {}}>
          {navigationList.map(({ name, icon, page }: any, index) => (
            <ListItem disablePadding onClick={() => navigate(page)} key={name}>
              <ListItemButton
                className={clsx(
                  classes.listItemButton,
                  location.pathname === page
                    ? classes.listItemButtonActive
                    : "",
                )}
                disableRipple
                style={
                  open
                    ? {}
                    : { width: 48, height: 48, justifyContent: "center" }
                }>
                <ListItemIcon className={classes.listItemIcon}>
                  <SvgIcon name={icon} className={classes.svgIcon} />
                </ListItemIcon>
                <ListItemText
                  className={classes.listItemText}
                  primary={name}
                  sx={{ display: open ? "flex" : "none" }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </DrawerStyle>
    </Box>
  );
};

export default Sidebar;

interface Props extends DrawerProps {
  navigationList: NavigationListItem[];
}

type ClassNames =
  | "root"
  | "svgIcon"
  | "buttonOpen"
  | "buttonOpenSvg"
  | "buttonLogo"
  | "svgLogo"
  | "listItemButton"
  | "listItemButtonActive"
  | "listItemIcon"
  | "listItemText"
  | "list"
  | "drawerHeader";

const listItemButtonActive = {};

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    backgroundColor: COLORS.primary.darkGreen,
  },
  svgIcon: {
    color: COLORS.primary.orange,
    width: 24,
    height: 24,
  },
  buttonOpen: {
    zIndex: 9999,
    display: "flex",
    width: "26px",
    height: "26px",
    padding: "8px 12px",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    right: "-6px",
    top: "13px",
    borderRadius: "4px",
    background: COLORS.secondary.green,

    "&:hover": {
      background: COLORS.primary.green,
    },
  },
  buttonOpenSvg: {
    width: "18px",
    height: "18px",
    flexShrink: "0",
    color: COLORS.primary.grey30,
  },
  buttonLogo: {
    padding: 0,
    minHeight: 0,
  },
  list: {},

  listItemButtonActive: {
    background: COLORS.secondary.green,
    "& .MuiTypography-root": {
      color: `${COLORS.primary.orange} !important`,
    },
  },
  listItemButton: {
    width: "160px",
    height: "48px",
    borderRadius: "4px",

    "&:hover": {
      background: "rgba(66, 136, 132, 0.7)",
      "& .MuiTypography-root": {
        color: COLORS.primary.orange,
      },
    },

    "&:active": {
      background: "rgba(66, 136, 132, 0.9)",
      "& .MuiTypography-root": {
        color: COLORS.primary.orange,
      },
    },
  },

  listItemIcon: {
    minWidth: "unset",
  },
  listItemText: {
    paddingLeft: SPACING.s_8,
    "& .MuiTypography-root": {
      ...(theme.typography.h3 as any),
      color: COLORS.primary.grey40,
    },
  },
  svgLogo: {},
  drawerHeader: {
    minHeight: "0 !important",
    marginBottom: SPACING.s_30,
  },
}));
