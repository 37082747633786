import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { FirmsStats } from "../../stats/types";
import { fetchStatistics } from "./statistics.thunk";

interface StatisticsState {
  data: FirmsStats;
  status: "idle" | "loading" | "succeeded" | "failed";
  isLoading: boolean;
  error: string | null;
}

const initialState: StatisticsState = {
  data: {
    industries: [],
    officeTypes: [],
    continents: [],
    countries: [],
    states: [],
  },
  status: "idle",
  isLoading: false,
  error: null,
};

export const sliceStatistics = createSlice({
  name: "statistics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStatistics.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(
        fetchStatistics.fulfilled,
        (state, action: PayloadAction<FirmsStats>) => {
          state.status = "succeeded";
          state.isLoading = false;
          state.data = action.payload;
        },
      )
      .addCase(fetchStatistics.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});
