import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import * as React from "react";
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import * as yup from "yup";

import { useApiRequest } from "../../../../hooks/useApiRequest";
import { API_ENDPOINTS } from "../../../../middleware/api/endpoints.api";
import { COLORS } from "../../../../theme/colors";
import TextField from "../../../atoms/@form/TextField";
import useForm from "../../../atoms/@form/useForm";
import { AlertContext } from "../../../atoms/Alert/AlertContextProvider";
import Button from "../../../atoms/Button";
import Dialog from "../../../atoms/Dialog";
import DialogActions from "../../../atoms/DialogActions";
import DialogContent from "../../../atoms/DialogContent";
import DialogTitle from "../../../atoms/DialogTitle";
import Link from "../../../atoms/Link";
import Typography from "../../../atoms/Typography";

const MAX_ATTEMPTS = 3;
const COUNTDOWN_TIME = 60;

const validationSchema = yup.object().shape({
  code: yup
    .string()
    .matches(/^[0-9]{4}$/, "Code must be exactly 4 numbers")
    .required("Code is required"),
});

const DialogPhoneNumberVerification = forwardRef<any, Props>(
  ({ onVerify }: Props, ref) => {
    const { classes } = useStyles();
    const [requestSendCode, postRequestSendCode] = useApiRequest<any, any>(
      API_ENDPOINTS.AUTH.PHONE_SEND_CODE,
      "POST",
    );
    const [requestVerifyCode, postRequestVerifyCode] = useApiRequest<any, any>(
      API_ENDPOINTS.AUTH.PHONE_VERIFY_CODE,
      "POST",
    );
    const [open, setOpen] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [attempts, setAttempts] = useState(0);
    const [parentData, setParentData] = useState<any>(null);

    const isResendEnabled = countdown <= 0;
    const form = useForm({
      defaultValues: {
        code: "",
      },
      resolver: yupResolver(validationSchema),
    });

    const {
      formState: { errors, isValid },
      register,
      handleSubmit,
      reset,
    } = form;

    useImperativeHandle(ref, () => ({
      handleOpen(data: any) {
        reset();
        setOpen(true);
        setParentData(data);
        handleResendCode(data);
      },
      handleClose() {
        setOpen(false);
      },
    }));

    useEffect(() => {
      let interval: NodeJS.Timeout;
      if (open && countdown > 0) {
        interval = setInterval(() => {
          setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);
      }
      return () => clearInterval(interval);
    }, [open, countdown]);

    const onClose = () => setOpen(false);

    const { showAlert } = useContext(AlertContext);

    const verifyCode = (data: any) => {
      postRequestVerifyCode({
        code: data.code,
        phoneNumber: parentData.phoneNumber,
      })
        .then(async (data) => {
          if (data?.approved) {
            showAlert("Phone number verified successfully", "success");
            onVerify(parentData);
            onClose();
          } else {
            showAlert("Invalid code", "error");
          }
        })
        .catch((error) => {
          console.error("Error fetching requests:", error);
          // setAttempts((prevAttempts) => prevAttempts + 1);
        });
    };

    const onSubmit = async (data: { code: string }) => {
      verifyCode(data);
    };

    const handleResendCode = (data: any) => {
      if (isResendEnabled) {
        setCountdown(COUNTDOWN_TIME);

        postRequestSendCode({
          phoneNumber: data.phoneNumber,
        }).catch((error) => {
          console.error("Error fetching requests:", error);
        });
      }
    };

    const formatTime = (time: number) => {
      const minutes = Math.floor(time / 60);
      const seconds = time % 60;
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    };

    return (
      <Dialog onClose={onClose} open={open} fullWidth>
        <DialogTitle
          caption={{
            title: "Phone number verification",
            description: "Please enter the 4 digit code sent to your phone",
          }}
          onClose={onClose}
        />
        <DialogContent style={{ marginTop: -48, paddingTop: 48 }}>
          <TextField
            inputProps={{ maxLength: 4 }}
            style={{ width: "100%" }}
            id="code"
            label="Code"
            {...register("code")}
            error={!!errors.code}
            helperText={errors.code?.message}
          />
          <Box
            style={{
              display: "flex",
              paddingTop: 48,
              paddingBottom: 48,
              justifyContent: "space-between",
            }}>
            <Box style={{ display: "flex" }}>
              <Typography
                variant="overline"
                style={{ color: COLORS.secondary.red, marginRight: 8 }}>
                {formatTime(countdown)}
              </Typography>
              <Link
                // disabled={!isResendEnabled || attempts >= MAX_ATTEMPTS}
                disabled={!isResendEnabled}
                variant="h4"
                style={{
                  textDecoration: "underline",
                }}
                onClick={() => handleResendCode(parentData)}>
                Resend code
              </Link>
            </Box>
            <Box style={{ display: "flex" }}>
              <Typography variant="overline" style={{ marginRight: 8 }}>
                Back to
              </Typography>
              <Link
                variant="h4"
                style={{ textDecoration: "underline" }}
                onClick={onClose}>
                Sign up
              </Link>
            </Box>
          </Box>
          {/*<Typography variant="body2" color="error">*/}
          {/*  Attempts remaining: {MAX_ATTEMPTS - attempts}*/}
          {/*</Typography>*/}
        </DialogContent>
        <DialogActions style={{ paddingTop: 0 }}>
          <Button type="button" typeClassName="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="button"
            isSpinner={requestVerifyCode.isLoading}
            typeClassName="primary"
            disabled={!isValid}
            onClick={handleSubmit(onSubmit)}>
            Verify
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);

DialogPhoneNumberVerification.displayName = "DialogPhoneNumberVerification";
export default DialogPhoneNumberVerification;

import { DialogProps } from "@mui/material";

interface Props extends Omit<DialogProps, "open"> {
  onVerify: (data: any) => void;
}

import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

type ClassNames = "root";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {},
}));
