import { Dialog as MUIDialog, DialogProps, Theme } from "@mui/material";
import * as React from "react";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { makeStyles } from "tss-react/mui";

const Dialog: FC<Props> = ({ children, onClose, open, ...rest }: Props) => {
  const { classes } = useStyles();
  const { control, register, handleSubmit, setValue } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((show: any) => !show);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <>
      <MUIDialog
        maxWidth="md"
        className={classes.root}
        onClose={onClose}
        PaperProps={{ classes: { root: classes.paper } }}
        open={open}
        {...rest}>
        {children}
      </MUIDialog>
    </>
  );
};

export default Dialog;

interface Props extends DialogProps {
  onClose: any;
}

type ClassNames = "root" | "paper";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    minWidth: theme.breakpoints.values.md,
  },
  paper: {
    "& *::-webkit-scrollbar": {
      display: "none",
    },
    "&::-webkit-scrollbar": { display: "none" },
    borderRadius: 20,
    boxShadow: "none",
    maxWidth: 800,
  },
}));
