import _ from "lodash";

export const filterRowsByQueryString = (
  data: any[] | undefined,
  query: string,
) => {
  if (!data) return data;
  if (!query) return data;

  const lowerCaseQuery = query.toLowerCase();

  const searchDeep = (obj: any): boolean => {
    if (obj === null || obj === undefined) {
      return false;
    }

    if (typeof obj === "object") {
      return Object.values(obj).some((value) => searchDeep(value));
    }

    if (Array.isArray(obj)) {
      return obj.some((item) => searchDeep(item));
    }

    return obj.toString().toLowerCase().includes(lowerCaseQuery);
  };

  return data.filter((item) => searchDeep(item));
};

export const filterRowsByQueryArray = (
  data: any[] | undefined,
  queries: string[],
) => {
  if (!data) return data;
  if (queries.length === 0) return data;

  const lowerCaseQueries = queries.map((query) => query.toLowerCase());

  const searchDeep = (obj: any): boolean => {
    if (obj === null || obj === undefined) {
      return false;
    }
    if (typeof obj === "object") {
      return Object.values(obj).some((value) => searchDeep(value));
    }
    if (Array.isArray(obj)) {
      return obj.some((item) => searchDeep(item));
    }
    return lowerCaseQueries.some((query) =>
      obj.toString().toLowerCase().includes(query),
    );
  };

  return data.filter((item) => searchDeep(item));
};

interface DateRangeObject {
  createdAt: string;
  updatedAt: string;
}

export const filterRowsByDateRange = (
  data: DateRangeObject[],
  type: "createdAt" | "updatedAt",
  dateRange: [string, string],
): DateRangeObject[] => {
  const [startDate, endDate] = dateRange;

  return data.filter((item) => {
    const date = new Date(item[type]);
    return date >= new Date(startDate) && date <= new Date(endDate);
  });
};

export const updateObjectsByIds = (
  array: any[],
  ids: any[],
  updateFunction: any,
) => {
  return array.map((obj) => {
    if (ids.includes(obj.id)) {
      return updateFunction(obj);
    }
    return obj;
  });
};

type ObjectWithArrays = {
  [key: string]: any[] | string[];
};

export function mergeObjectsWithNonEmptyArrays(
  obj1: ObjectWithArrays,
  obj2: ObjectWithArrays,
): ObjectWithArrays {
  const customizer = (objValue: any[], srcValue: any[]): any[] | undefined => {
    if (Array.isArray(objValue) && Array.isArray(srcValue)) {
      return srcValue.length > 0 ? srcValue : objValue;
    }
    return undefined;
  };

  return _.mergeWith({}, obj1, obj2, customizer);
}

export function mapKeys(
  inputKeys: string[],
  keyValueMapping: Array<[string[], string[]]>,
): string[] {
  const mappingMap = new Map(
    keyValueMapping.flatMap(([keys, values]) =>
      keys.map((key) => [
        normalizeKey(key),
        { original: values[0], lower: values[0].toLowerCase() },
      ]),
    ),
  );
  const values = inputKeys
    .map((key) => {
      const normalizedKey = normalizeKey(key);
      const mappedValue = mappingMap.get(normalizedKey);
      return mappedValue ? mappedValue.original : undefined;
    })
    .filter((mappedValue): mappedValue is string => mappedValue !== undefined);

  return Array.from(new Set(values));
}

function normalizeKey(key: string): string {
  return key.toLowerCase().replace(/_/g, " ");
}
