import { Alert as MUIAlert, AlertProps, Theme } from "@mui/material";
import React, { FC } from "react";
import { makeStyles } from "tss-react/mui";

import { ZIndexType } from "../../../@types/zIndex.type";
import { COLORS } from "../../../theme/colors";
import { SPACING } from "../../../theme/spacing";
import SvgIcon from "../SvgIcon";

const Alert: FC<Props> = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    const { classes } = useStyles();

    return (
      <MUIAlert
        iconMapping={{
          success: (
            <SvgIcon
              name="circleCheck"
              style={{ color: COLORS.primary.green }}
              width={30}
              height={30}
            />
          ),
          warning: (
            <SvgIcon
              name="warning"
              style={{ color: COLORS.secondary.red }}
              width={32}
              height={32}
            />
          ),
          error: (
            <SvgIcon
              name="warningDiamond"
              style={{ color: COLORS.primary.white }}
              width={32}
              height={32}
            />
          ),
        }}
        classes={{ root: classes.root }}
        elevation={6}
        ref={ref}
        variant="filled"
        {...props}
      />
    );
  },
);

export default Alert;

interface Props extends AlertProps {}

type ClassNames = "root" | "alertsContainer";

export const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    display: "flex",
    padding: "24px 32px",
    alignItems: "center",
    flexShrink: 0,
    borderRadius: "10px",
    boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.07)",

    "& .MuiAlert-message": {
      ...(theme.typography.body1 as any),
      color: "inherit",
      padding: 0,
    },
    "& .MuiAlert-icon": {
      marginRight: SPACING.s_12,
      padding: 0,
    },
  },
  alertsContainer: {
    position: "fixed",
    zIndex: ZIndexType.alerts,
    top: 25,
    right: 40,
    "& > *": {
      marginTop: SPACING.s_10,
    },
    "& > *:first-of-type": {
      marginTop: 0,
    },
  },
}));
