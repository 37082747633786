import React, {
  ComponentProps,
  FC,
  useCallback,
  useContext,
  useRef,
} from "react";

import { handleError } from "../middleware/http";
import { AlertContext } from "./atoms/Alert/AlertContextProvider";

const ApiErrorHandler: FC<Props> = ({ children }) => {
  const { showAlert } = useContext(AlertContext);

  const isSubscribed = useRef(false);

  const showErrorMessage = useCallback(
    (message: string) => {
      showAlert(message, "error");
    },
    [showAlert],
  );

  if (!isSubscribed.current) {
    handleError((message: string) => {
      showErrorMessage(message);
    });

    isSubscribed.current = true;
  }

  return <></>;
};

interface Props extends ComponentProps<any> {}

export default ApiErrorHandler;
