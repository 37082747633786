import React, { FC, useMemo } from "react";
import { HelmetProps } from "react-helmet";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { titleCase } from "title-case";

import { basename } from "../../../utils/string.util";

const Meta: FC<Props> = (props: Props) => {
  const { ...rest } = props;
  const location = useLocation();

  const title = useMemo(() => {
    const text = "Family Office List";
    const title = titleCase(basename(location.pathname.replace("-", " ")));

    return `${title} | ${text}`;
  }, [location.pathname]);

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

export default Meta;

interface Props extends HelmetProps {}
