export function omit(obj: any, omittedKeys: any) {
  if (!obj || typeof obj !== "object") {
    return obj;
  }
  const ret = {};
  Object.keys(obj).forEach((key) => {
    if (omittedKeys.indexOf(key) > -1) {
      return;
    }
    // @ts-ignore
    ret[key as any] = obj[key];
  });
  return ret;
}
