import { Box, RadioGroup } from "@mui/material";
import * as React from "react";
import { ComponentProps, FC, useMemo } from "react";
import { useController } from "react-hook-form";
import { UseFormReturn } from "react-hook-form/dist/types/form";

import FormControlLabel from "../../../atoms/@form/FormControlLabel";
import FormHelperText from "../../../atoms/@form/FormHelperText";
import Radio from "../../../atoms/@form/Radio";
import TextField from "../../../atoms/@form/TextField";
import Typography from "../../../atoms/Typography";
import { useStyles } from "../styles";

const SectionDescribesBusiness: FC<Props> = ({
  form: {
    formState: { errors },
    control,
  },
}: Props) => {
  const { classes } = useStyles();
  const labels = useMemo(
    () => [
      "3rd Party Marketer",
      "Family Office",
      "Fund Manager",
      "Investment Advisor",
      "Private Equity",
      "Service Provider",
      "Start-Up Founder",
      "Venture Capital",
      "Other",
    ],
    [],
  );

  const {
    field: businessField,
    fieldState: { error },
  } = useController({
    name: "business",
    control,
  });

  const { field: otherBusinessField } = useController({
    name: "businessOther",
    control,
  });

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    businessField.onChange(event.target.value);
    if (event.target.value !== "Other") {
      otherBusinessField.onChange("");
    }
  };

  const handleOtherTextChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    otherBusinessField.onChange(event.target.value);
    businessField.onChange("Other");
  };

  return (
    <Box className={classes.section}>
      <Typography variant="h2" className={classes.sectionTitle}>
        1. What best describes your business? *
      </Typography>
      <RadioGroup
        {...businessField}
        onChange={handleRadioChange}
        className={classes.sectionContent}>
        {labels.map((label: string) => (
          <FormControlLabel
            key={label}
            value={label}
            control={<Radio />}
            disableTypography={label === "Other"}
            label={
              label === "Other" ? (
                <TextField
                  {...otherBusinessField}
                  value={otherBusinessField.value || ""}
                  onChange={handleOtherTextChange}
                  placeholder={label}
                  inputRef={otherBusinessField.ref}
                  onBlur={otherBusinessField.onBlur}
                  fullWidth
                  disabled={businessField.value !== "Other"}
                />
              ) : (
                label
              )
            }
          />
        ))}
      </RadioGroup>
      {error && <FormHelperText>{error.message}</FormHelperText>}
    </Box>
  );
};

interface Props extends ComponentProps<any> {
  form: UseFormReturn<any>;
}

export default SectionDescribesBusiness;
