import { Badge as MUIBadge, BadgeProps, Theme } from "@mui/material";
import React, { FC } from "react";
import { makeStyles } from "tss-react/mui";

import { COLORS } from "../../../theme/colors";

const Badge: FC<Props> = ({ children, ...rest }) => {
  const { classes } = useStyles();
  return (
    <MUIBadge classes={{ badge: classes.badge, root: classes.root }} {...rest}>
      {children}
    </MUIBadge>
  );
};

export default Badge;

interface Props extends BadgeProps {}

type ClassNames = "root" | "badge";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  badge: {
    ...(theme.typography.caption as any),
    borderRadius: "90px",
    background: COLORS.primary.orange,
    color: COLORS.primary.white,
    fontWeight: "500",
    bottom: "-15px",
    top: "auto",
    right: "auto",
  },
}));
