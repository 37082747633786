import {
  FormControlLabel as MUIFormControlLabel,
  FormControlLabelProps,
  Theme,
} from "@mui/material";
import React, { FC, forwardRef } from "react";
import { makeStyles } from "tss-react/mui";

const FormControlLabel: FC<Props> = forwardRef(({ ...rest }, ref) => {
  const { classes } = useStyles();
  return <MUIFormControlLabel className={classes.root} {...rest} ref={ref} />;
});
FormControlLabel.displayName = "FormControlLabel";
export default FormControlLabel;

interface Props extends FormControlLabelProps {}

type ClassNames = "root";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    "& .MuiCheckbox-root": {
      marginLeft: "11px",
      alignSelf: "flex-start",
    },
    "& .MuiRadio-root": {
      marginLeft: "10px",
    },
  },
}));
