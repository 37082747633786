import { useCallback, useEffect, useRef, useState } from "react";

import { useApiRequest } from "../../../../../hooks/useApiRequest";
import { API_ENDPOINTS } from "../../../../../middleware/api/endpoints.api";
import { useFetchFilters } from "../../../../../middleware/api/offices/filters/useFetchFilters.hook";
import { FiltersAll } from "../../../../../middleware/api/offices/types";

export function useHookFiltersAll() {
  const [filtersTypes, setFiltersTypes] = useState<FiltersAll | null>(null);
  const [filtersTypesInitial, setFiltersTypesInitial] =
    useState<FiltersAll | null>(null);

  const [filtersTypesActive, setFiltersTypesActive] =
    useState<FiltersAll | null>(null);

  const [filtersTypesRequestState, sendRequest] = useApiRequest<
    FiltersAll,
    any
  >(API_ENDPOINTS.OFFICES.FILTERS_TYPES, "POST");

  const { data: filters, isLoading } = useFetchFilters();

  const requestSentRef = useRef(false);

  const getRequests = useCallback(() => {
    sendRequest()
      .then((data) => {
        if (data) {
          setFiltersTypes(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching requests:", error);
      });
  }, [sendRequest]);

  useEffect(() => {
    if (requestSentRef.current) return;
    if (filters) {
      requestSentRef.current = true;
      setFiltersTypes(filters);
      setFiltersTypesInitial(filters);
    }
  }, [
    getRequests,
    filtersTypesRequestState,
    filtersTypesRequestState.data,
    sendRequest,
    filters,
  ]);

  return {
    filters: {
      filtersTypes,
      filtersTypesInitial,
      filtersTypesActive,
      setFiltersTypesActive,
      filtersTypesRequestState,
    },
  };
}
