import { array, object, string } from "yup";

import { FORM_VALIDATION_RULES } from "../../atoms/@form/validationRules";

export const validationSchema = object().shape({
  // settings: array(),
  title: string()
    .min(1, "Title must be at least 1 characters")
    .max(30, "Title must be at most 30 characters"),
  firstName: FORM_VALIDATION_RULES.firstName,
  lastName: FORM_VALIDATION_RULES.lastName,
  avatars: array().of(FORM_VALIDATION_RULES.file),
  // password: FORM_VALIDATION_RULES.password,
});
