import { Palette } from "@mui/material";
import createPalette from "@mui/material/styles/createPalette";

import { COLORS } from "./colors";

const palette: Palette = createPalette({
  primary: {
    light: COLORS.primary.lightGreen,
    main: COLORS.primary.green,
    dark: COLORS.primary.lightGreen,
    contrastText: COLORS.primary.lightGreen,
  },
  error: {
    light: COLORS.secondary.lightRed,
    main: COLORS.secondary.red,
    dark: COLORS.secondary.darkRed,
    contrastText: COLORS.primary.white,
  },
  warning: {
    light: COLORS.secondary.lightOrange,
    main: COLORS.secondary.yellow,
    dark: COLORS.primary.orange,
    contrastText: COLORS.secondary.darkOrange,
  },
  success: {
    light: COLORS.secondary.lightGreen10,
    main: COLORS.secondary.lightGreen20,
    dark: COLORS.primary.green,
    contrastText: COLORS.secondary.dark,
  },
});

export default palette;
