import {
  Autocomplete as MUIAutocomplete,
  AutocompleteProps,
  Chip,
  TextFieldProps,
  Theme,
} from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import React, { FC, forwardRef } from "react";
import { makeStyles } from "tss-react/mui";

import { COLORS } from "../../../theme/colors";
import TextField from "../../atoms/@form/TextField";
import SvgIcon from "../../atoms/SvgIcon";

const renderOption = (props: any, option: any, { inputValue }: any) => {
  const matches = match(option.title, inputValue, { insideWords: true });
  const parts = parse(option.title, matches);

  return (
    <li {...props}>
      <div>
        {parts.map((part: any, index: any) => (
          <span
            key={index}
            style={
              part.highlight
                ? {
                    color: part.highlight,
                  }
                : {}
            }>
            {part.text}
          </span>
        ))}
      </div>
    </li>
  );
};

const renderInput = (props: any, propsTextField: any) => (
  <TextField
    {...props}
    variant="standard"
    label="Label Text"
    placeholder=""
    {...propsTextField}
    aria-autocomplete="none"
    onKeyDown={(event: React.KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
      }
    }}
    inputProps={{
      ...props.inputProps,
      autoComplete: "disable",
    }}
  />
);

const Autocomplete: FC<Props> = forwardRef(
  ({ options = [], formControlField, propsTextField, ...rest }, ref) => {
    const { classes } = useStyles();

    const onChange = (_: any, data: any, field: any) => {
      const uniqueData = Array.from(
        new Set(data.map((item: any) => item.title)),
      ).map((title) => data.find((item: any) => item.title === title));
      field.onChange(uniqueData);
    };

    const filterOptions = (options: any, state: any) => {
      const validOptions = options.filter(
        (option: any) =>
          option?.title !== null &&
          option?.title !== undefined &&
          option?.title !== "",
      );

      return validOptions.filter((option: any) =>
        option.title.toLowerCase().includes(state.inputValue.toLowerCase()),
      );
    };

    return (
      <MUIAutocomplete
        isOptionEqualToValue={(option: any, value: any) =>
          option?.title === value?.title
        }
        multiple
        className={classes.root}
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: any, index: number) => (
            <Chip
              {...getTagProps({ index })}
              key={index}
              className={classes.chip}
              deleteIcon={<SvgIcon name="close" width={18} height={18} />}
              label={option.title}
            />
          ))
        }
        popupIcon={<SvgIcon name="chevronDown" width={18} height={18} />}
        classes={{ paper: classes.paper }}
        options={options}
        getOptionLabel={(option: any) => option.title}
        renderOption={renderOption}
        filterOptions={filterOptions}
        renderInput={(props: any) => renderInput(props, propsTextField)}
        clearIcon={<></>}
        {...rest}
        {...(formControlField && {
          onChange: (_, data) => {
            onChange(_, data, formControlField);
          },
        })}
        ref={ref}
      />
    );
  },
);

Autocomplete.displayName = "Autocomplete";

export default Autocomplete;

interface Props extends Partial<AutocompleteProps<any, any, any, any, any>> {
  options: { title: string }[];
  renderInput?: any;
  propsTextField?: TextFieldProps;
  formControlField?: any;
}

type ClassNames = "root" | "paper" | "chip";

const chip = {
  border: "none",
  backgroundColor: "unset",
  height: 20,
};
const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    ...(theme.typography.body1 as any),
    "& .MuiChip-label": {
      ...(theme.typography.body1 as any),
      paddingLeft: 0,
    },
    "& .MuiInputLabel-root": {
      color: COLORS.primary.grey50,
    },
    "& .MuiInputBase-root:after": {
      borderWidth: "0.5px !important",
      borderColor: COLORS.primary.green,
      borderStyle: "solid",
    },
    "& .MuiInputBase-root:before": {
      borderBottomWidth: 1,
      borderColor: COLORS.primary.grey30,
      borderBottomStyle: "solid",
    },

    "& .MuiInputBase-root": {
      "& .MuiSvgIcon-root": {
        width: 18,
        height: 18,
        color: COLORS.primary.grey40,

        "&:hover": {
          color: COLORS.primary.darkGreen,
        },
      },
    },

    "& .MuiAutocomplete-endAdornment": {
      "& .MuiSvgIcon-root": {
        width: 18,
        height: 18,
        color: COLORS.primary.dark,
      },
    },

    "&:hover": {
      "& .MuiAutocomplete-endAdornment": {
        "& .MuiSvgIcon-root": {
          color: COLORS.primary.darkGreen,
        },
      },
    },

    "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error)::before": {
      borderBottomWidth: 1,
      borderColor: COLORS.primary.green,
      borderBottomStyle: "solid",
    },
  },
  paper: {
    borderRadius: "unset",
    boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.07)",

    "& .MuiAutocomplete-listbox .MuiAutocomplete-option": {
      minHeight: 40,
    },

    "& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused": {
      backgroundColor: COLORS.primary.grey10,
    },
    "& .MuiAutocomplete-listbox .MuiAutocomplete-option:active": {
      backgroundColor: COLORS.secondary.green,
      color: COLORS.primary.white,
    },
    '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]':
      {
        backgroundColor: COLORS.primary.green,
        color: COLORS.primary.white,
      },
    '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"].Mui-focused':
      {
        backgroundColor: COLORS.primary.darkGreen,
        color: COLORS.primary.white,
      },
  },
  chip,
}));
