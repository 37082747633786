import {
  FormHelperText as MUIFormHelperText,
  FormHelperTextProps,
  Theme,
} from "@mui/material";
import React, { FC } from "react";
import { makeStyles } from "tss-react/mui";

import { COLORS } from "../../../theme/colors";

const FormHelperText: FC<Props> = ({ children, ...rest }) => {
  const { classes } = useStyles();
  return (
    <MUIFormHelperText className={classes.root} {...rest}>
      {children}
    </MUIFormHelperText>
  );
};

export default FormHelperText;

interface Props extends FormHelperTextProps {}

type ClassNames = "root";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    color: COLORS.secondary.red,
  },
}));
