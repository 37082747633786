import React from "react";

import { TabEnum } from "../context";
import { useHookListAll } from "./useHookListAll";
import { useHookListFavorite } from "./useHookListFavorite";
import { useHookListSavedSearch } from "./useHookListSavedSearch";

export function useHookTabs() {
  const [activeTab, setActiveTab] = React.useState(TabEnum.ALL);
  const { listAll } = useHookListAll(activeTab === TabEnum.ALL);
  const { listFavorite } = useHookListFavorite();
  const { listSavedSearch } = useHookListSavedSearch(false);

  return {
    activeTab,
    setActiveTab,
    listAll,
    listFavorite,
    listSavedSearch,
  };
}
