import React, { FC } from "react";

import { Environments } from "../../../@types/environments.enum";
import Facebook from "./components/Facebook";
import GoogleTagManager from "./components/GoogleTagManager";
import GoogleTagManagerHeader from "./components/GoogleTagManagerHeader";
import Hotjar from "./components/Hotjar";
import Intercom from "./components/Intercom";
import MsAppInsights from "./components/MsAppInsights";
import MsClarity from "./components/MsClarity";

const ThirdPartyLibraries: FC = () => {
  if (process.env.REACT_APP_ENV === Environments.DEVELOPMENT) return <></>;
  return (
    <>
      <Hotjar />
      <GoogleTagManagerHeader />
      <GoogleTagManager />
      <Facebook />
      <MsClarity />
      <MsAppInsights />
      <Intercom />
    </>
  );
};

export default ThirdPartyLibraries;
