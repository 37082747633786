import { useCallback, useState } from "react";

import { useApiRequest } from "../../../../../hooks/useApiRequest";
import { API_ENDPOINTS } from "../../../../../middleware/api/endpoints.api";
import { FiltersAll } from "../../../../../middleware/api/offices/types";

export function useHookFiltersRefine() {
  const [filtersTypesRefine, setFiltersTypesRefine] =
    useState<FiltersAll | null>(null);

  const [filtersTypesRefineActive, setFiltersTypesRefineActive] =
    useState<FiltersAll | null>(null);

  const [requestStateFiltersTypesRefine, sendRequest] = useApiRequest<
    FiltersAll,
    any
  >(API_ENDPOINTS.OFFICES.FILTERS_TYPES, "POST", false);

  const getFiltersTypesRefine = useCallback(
    (filtersRefine = {}): Promise<any> => {
      return sendRequest(filtersRefine)
        .then((data) => {
          if (data) {
            setFiltersTypesRefine(data);
            return Promise.resolve(data);
          }
          return Promise.resolve(null);
        })
        .catch((error) => {
          console.error("Error fetching requests:", error);
          return Promise.resolve(null);
        });
    },
    [sendRequest],
  );

  return {
    filtersRefine: {
      filtersTypesRefine,
      setFiltersTypesRefine,
      filtersTypesRefineActive,
      setFiltersTypesRefineActive,
      requestStateFiltersTypesRefine,
      getFiltersTypesRefine,
    },
  };
}
