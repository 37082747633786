import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RoleEnum } from "../middleware/@types/user.type";
import { StateAuth } from "../middleware/api/auth/stateEntity/reducer";
import { AppState } from "../store/rootReducer";

export function useIsCustomer() {
  const { user } = useSelector<AppState, StateAuth>((state) => state.auth);
  const [role, setIsRole] = useState(user?.role === RoleEnum.customer);

  useEffect(() => {
    setIsRole(user?.role === RoleEnum.customer);
  }, [user?.role]);

  return role;
}
