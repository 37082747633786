import React from "react";
import ReactDOM from "react-dom/client";

import App from "./components/App";
import { trackException } from "./components/atoms/ThirdPartyLibraries/AppInsights";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

if (process.env.REACT_APP_ENV === "production") {
  serviceWorkerRegistration.register();

  window.addEventListener("unhandledrejection", (event) => {
    trackException(event.reason, {
      componentStack: "Unhandled Rejection",
    });
  });

  window.addEventListener("error", (event) => {
    trackException(event.error, {
      componentStack: "Uncaught Error",
    });
  });
} else {
  reportWebVitals(console.log);
  serviceWorkerRegistration.unregister();
}
export { areAllValuesEmpty } from "./components/atoms/@charts/utils";
