import "./App.scss";

import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { Box, ThemeProvider } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import React, { useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { HelmetProvider } from "react-helmet-async";
import { I18nextProvider, useTranslation } from "react-i18next";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { BrowserRouter, useLocation } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";
import { PersistGate } from "redux-persist/integration/react";

import { Environments } from "../@types/environments.enum";
import config from "../config";
import i18next from "../i18n/config";
import { persistor, store } from "../store/store";
import { COLORS } from "../theme/colors";
import theme from "../theme/theme";
import ApiErrorHandler from "./ApiErrorHandler";
import AlertContainer from "./atoms/Alert/AlertContainer";
import AlertContextProvider from "./atoms/Alert/AlertContextProvider";
import Button from "./atoms/Button";
import Meta from "./atoms/Meta";
import ThirdPartyLibraries from "./atoms/ThirdPartyLibraries";
import { reactPlugin } from "./atoms/ThirdPartyLibraries/AppInsights";
import Pages from "./pages";

loadStripe.setLoadParameters({ advancedFraudSignals: false });

const stripePromise = loadStripe(process.env.REACT_APP_KEY_STRIPE || "");
const INTERCOM_ID = process.env.REACT_APP_KEY_INTERCOM || "vi2kuzop";
const { KEY_RECAPTCHA = "" } = config;

function App() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={KEY_RECAPTCHA}>
      <IntlProvider locale="en">
        <I18nextProvider i18n={i18next}>
          <HelmetProvider>
            <AppInsightsContext.Provider value={reactPlugin}>
              <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                  <ThemeProvider theme={theme}>
                    <Elements stripe={stripePromise}>
                      <BrowserRouter>
                        <IntercomProvider
                          appId={INTERCOM_ID}
                          autoBoot
                          shouldInitialize={
                            process.env.REACT_APP_ENV !==
                            Environments.DEVELOPMENT
                          }>
                          <ScrollToTop />
                          <Meta />
                          <ThirdPartyLibraries />
                          <AlertContextProvider>
                            <>
                              <Pages />
                              <AlertContainer />
                              <ApiErrorHandler />
                            </>
                          </AlertContextProvider>
                          <ThirdPartyLibraries />
                        </IntercomProvider>
                      </BrowserRouter>
                    </Elements>
                  </ThemeProvider>
                </PersistGate>
              </Provider>
            </AppInsightsContext.Provider>
          </HelmetProvider>
        </I18nextProvider>
      </IntlProvider>
    </GoogleReCaptchaProvider>
  );
}

export default App;

const BarTop = () => {
  const { t } = useTranslation();

  return (
    <Box
      style={{
        display: "flex",
        height: "47px",
        padding: "6px 40px",
        justifyContent: "flex-end",
        alignItems: "center",
        background: COLORS.primary.white,
        boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.10)",
      }}>
      <Button type="submit" typeClassName="secondary" href="/design-system/">
        {t("design_system_details")}
      </Button>
    </Box>
  );
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <></>;
};
