import { useEffect, useState } from "react";

import { useApiRequest } from "../../../../../../../hooks/useApiRequest";
import {
  PaginationDto,
  SortDirection,
} from "../../../../../../../middleware/@types/pagination.type";
import { API_ENDPOINTS } from "../../../../../../../middleware/api/endpoints.api";
import { NotificationsEntity } from "../../../../../../../middleware/api/entities/NotificationsEntity";

export const LIMIT = 5;

const initialPagination: PaginationDto = {
  page: 1,
  limit: LIMIT,
  orderBy: "createdAt",
  orderDirection: SortDirection.DESC,
};

export function useHookList() {
  const [notifications, setNotifications] = useState<NotificationsEntity[]>([]);

  const [requestState, sendRequest, updateData, changePage] = useApiRequest<
    NotificationsEntity[],
    any
  >(
    API_ENDPOINTS.NOTIFICATIONS.NOTIFICATIONS,
    "GET",
    false,
    undefined,
    initialPagination,
  );

  useEffect(() => {
    if (requestState.data) {
      setNotifications(requestState.data);
    }
  }, [requestState.data]);

  return {
    setNotifications,
    notifications,
    sendRequest,
    requestState,
    updateData,
    changePage,
  };
}
