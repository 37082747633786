import { Box, Theme } from "@mui/material";
import * as React from "react";
import { FC, Suspense } from "react";
import { makeStyles } from "tss-react/mui";

import BoxContainer from "../../atoms/BoxContainer";
import PortalHeader from "../../atoms/PortalHeader";
import Typography from "../../atoms/Typography";

const Content = React.lazy(() => import("./Content"));
const PageResources: FC<Props> = (props: Props) => {
  return (
    <>
      <Header />
      <Suspense fallback={<></>}>
        <BoxContainer style={{ width: "100%" }}>
          <Content />
        </BoxContainer>
      </Suspense>
    </>
  );
};

const Header = () => {
  return (
    <PortalHeader>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}>
        <Typography variant="h2">Resources</Typography>
      </Box>
    </PortalHeader>
  );
};

export default PageResources;

interface Props {}

type ClassNames = "root";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    position: "relative",
  },
}));
