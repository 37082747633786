import { array, boolean, object, string } from "yup";

import { FORM_VALIDATION_RULES } from "../../atoms/@form/validationRules";

export const validationSchema = object().shape({
  firstName: FORM_VALIDATION_RULES.firstName.required("First Name is required"),
  lastName: FORM_VALIDATION_RULES.lastName.required("Last Name is required"),
  email: FORM_VALIDATION_RULES.email.required("Email is required"),
  title: string().required("Title is required"),
  phoneNumber: FORM_VALIDATION_RULES.phoneNumber.required(
    "Phone number is required",
  ),
  password: FORM_VALIDATION_RULES.password,
  passwordConfirm: FORM_VALIDATION_RULES.passwordConfirm,
  recaptcha: string(),
  notificationSettings: array(),
  isTermsAndConditions: boolean().oneOf(
    [true],
    "Please review and accept the Terms and Conditions to continue",
  ),
});
