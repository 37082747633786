import { SvgIcon as MUISvgIcon, SvgIconProps, Theme } from "@mui/material";
import clsx from "clsx";
import React, { FC, forwardRef, useState } from "react";
import { makeStyles } from "tss-react/mui";

import { DOMAIN_ICONS, DomainIconName } from "./type";

const SvgIcon: FC<Props> = forwardRef(
  ({ name, hoverName, className, ...rest }, ref) => {
    const { classes } = useStyles();
    const [isHovered, setIsHovered] = useState(false);

    const hoverProps = hoverName
      ? {
          onMouseEnter: () => setIsHovered(true),
          onMouseLeave: () => setIsHovered(false),
        }
      : {};
    return (
      <MUISvgIcon
        classes={{ root: classes.root }}
        className={clsx(className)}
        inheritViewBox={true}
        {...rest}
        {...hoverProps}
        ref={ref}
        component={DOMAIN_ICONS[isHovered && hoverName ? hoverName : name]}
      />
    );
  },
);

SvgIcon.displayName = "SvgIcon";

export default SvgIcon;

interface Props extends SvgIconProps {
  name: DomainIconName;
  hoverName?: DomainIconName;
}

type ClassNames = "root";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    width: "unset",
    height: "unset",
  },
}));
