import { yupResolver } from "@hookform/resolvers/yup";
import React, { ComponentProps, createContext, FC, useState } from "react";

import useForm from "../../../atoms/@form/useForm";
import { validationSchema } from "./form";

interface Props extends ComponentProps<any> {}

interface Context {
  mainForm: any;
  firmTypes: any;
  setFirmTypes: any;
}

export const DialogResearchRequestContext = createContext<Context>({
  mainForm: {},
} as Context);

const DialogResearchRequestContextProvider: FC<Props> = ({ children }) => {
  const mainForm = useForm({
    defaultValues: {
      specific: {
        firmName: "",
        website: "",
        country: "",
        city: "",
        firmContactPerson: "",
        comment: "",
        type: "specific",
      },
      general: {
        familyOfficeType: [],
        investmentSize: [],
        dealType: [],
        industry: [],
        country: "",
        city: "",
        targetGeography: [],
        comment: "",
        type: "general",
      },
    },
    resolver: yupResolver(validationSchema),
  });

  const [firmTypes, setFirmTypes] = useState(null);

  return (
    <DialogResearchRequestContext.Provider
      value={{
        mainForm,
        firmTypes,
        setFirmTypes,
      }}>
      {children}
    </DialogResearchRequestContext.Provider>
  );
};
export default DialogResearchRequestContextProvider;
