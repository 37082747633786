import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useIntercom } from "react-use-intercom";

import { StateAuth } from "../../../../../middleware/api/auth/stateEntity/reducer";
import { AppState } from "../../../../../store/rootReducer";
import { utilsIntercom } from "./utils";

interface IntercomSettings {
  app_id: string;
  name?: string;
  lead_source?: string;
  created_at?: number;
  user_id?: string;
  email?: string;
}

declare global {
  interface Window {
    intercomSettings: IntercomSettings;
    Intercom: any;
  }
}

const INTERCOM_ID = process.env.REACT_APP_KEY_INTERCOM || "vi2kuzop";

function parseCreatedAt(createdAt: string): number {
  const date = new Date(createdAt);

  return Math.floor(date.getTime() / 1000);
}

const Intercom: React.FC<any> = () => {
  const { boot, update, getVisitorId } = useIntercom();
  const { user } = useSelector<AppState, StateAuth>((state) => state.auth);

  const initRef = useRef(false);

  useEffect(() => {
    const intercomSettings: any = {
      app_id: INTERCOM_ID,
      api_base: "https://api-iam.intercom.io",
    };

    if (user) {
      intercomSettings.name = `${user.firstName} ${user.lastName}`;
      intercomSettings.lead_source = "App Test Drive";
      intercomSettings.created_at = parseCreatedAt(user.createdAt);
      intercomSettings.user_id = `app_familyofficelist_${user.id}`;
      intercomSettings.email = user.email;
      intercomSettings.user_hash = user.hashIntercom;
      window.intercomSettings = intercomSettings;
    }

    const callLeadSource = async () => {
      const userId = getVisitorId();
      const intercomArr = {
        app_id: INTERCOM_ID,
        v: "3",
        referer: window.location.href,
        api_base: "https://api-iam.intercom.io",
        page_title: "Family Office",

        user_data: {
          user_hash: intercomSettings.user_hash,
          anonymous_id: userId,
          user_id: intercomSettings.user_id,
          email: intercomSettings.email,
          name: intercomSettings.name,
        },
      };

      return utilsIntercom.pingIntercom(intercomArr);
    };

    if (initRef.current) return;
    if (user) {
      initRef.current = true;

      setTimeout(async () => {
        const data = await callLeadSource();
        if (data?.user?.has_conversations) {
          //@ts-ignore
          update({ lead_source: "App Live Chat" });
        }
      }, 5000);
    }
  }, [boot, update, getVisitorId, user]);

  return <></>;
};

export default Intercom;
