import { Box as MUIBox, BoxProps, Theme } from "@mui/material";
import clsx from "clsx";
import React, { FC } from "react";
import { makeStyles } from "tss-react/mui";

import { COLORS } from "../../../theme/colors";
import { SPACING } from "../../../theme/spacing";

const BoxContainer: FC<Props> = ({ children, className, ...rest }) => {
  const { classes } = useStyles();
  return (
    <MUIBox className={clsx(classes.root, className)} {...rest}>
      {children}
    </MUIBox>
  );
};

export default BoxContainer;

interface Props extends BoxProps {}

type ClassNames = "root";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    backgroundColor: COLORS.primary.white,
    padding: SPACING.s_30,
    borderRadius: SPACING.s_16,
  },
}));
