import { AppBar as MUIAppBar, AppBarProps, Box, Theme } from "@mui/material";
import clsx from "clsx";
import React, { FC } from "react";
import { makeStyles } from "tss-react/mui";

import { ZIndexType } from "../../../../../@types/zIndex.type";
import { SPACING } from "../../../../../theme/spacing";
import MenuAccount from "./components/MenuAccount";
import NotificationsWithIcon from "./components/NotificationsWithIcon";

const Header: FC<Props> = ({ children, ...rest }) => {
  const { classes } = useStyles();
  return (
    <>
      <MUIAppBar className={clsx(classes.root)} {...rest}>
        <Box id="portal-header" style={{ display: "flex", width: "100%" }} />

        <Box className={classes.sideRight}>
          <NotificationsWithIcon />
          <MenuAccount />
        </Box>
      </MUIAppBar>
      <Box
        id="portal-header-tablet"
        style={{ display: "flex", width: "100%" }}
      />
    </>
  );
};

export default Header;

interface Props extends AppBarProps {}

type ClassNames = "root" | "sideRight";

const useStyles = makeStyles<void, ClassNames>()((theme: Theme) => ({
  root: {
    height: 40,
    backgroundColor: "unset",
    boxShadow: "none",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "relative",
    zIndex: ZIndexType.aboveDefault,
  },
  sideRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > *": {
      marginRight: `${SPACING.s_30}px !important`,
    },
    "& > *:last-child": {
      marginRight: `0 !important`,
    },
  },
}));
